@import '../variables';
@import '../mixins';

#navbar-end-menu {
    width: fit-content;
    display: flex;
    justify-content: flex-end;
    margin-left: auto;
}

#navbar-search-large {
    display: flex;
}

/* NavBar */
#navbar-container {
    width: 100%; 
    height: 70px;
    padding: 10px 32px;
    position: fixed;
    top: 0;
    transition: top 0.3s;
    background-color: $light-background;
    z-index: 100;
    display: flex;
    align-items: center;
    @include respond-to (medium) {
        padding: 10px 20px;
    }
    @include respond-to (small) {
        padding: 10px 10px;
    }
}

.linko-navbar {
    width: 100%;
    height: 70px;
    // padding: 0 24px;
    // background-color: $light-background;
    // border-bottom: $border-width-thin solid $border-light;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    // position: fixed;
    // top: 0;
    // left: 0;
    // right: 0;
    // z-index: $z-index-sticky;

    @include respond-to(medium) {
        height: 60px;
        // padding: 0 16px;
    }
}
/* Left: logo */
.linko-navbar-brand {
    width: fit-content;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    flex-wrap: wrap;
    margin-right: 20px;
    margin-bottom: 3px;

    @include respond-to (medium) {
        margin-right: 10px;
    }

    .slogan {
        font-size: 12px;
        font-weight: 400;
        font-style: italic;
        color: $primary-color;
        margin-left: 2px;
    
        @include respond-to (small) {
            display: none;
        }
    }  
    
}

/* Right: search, buttons, profile */
.linko-navbar-end  {
    width: max-content;
    margin-left: 20px;
    display: flex;
    flex-wrap: nowrap;
    justify-content: flex-end;
    align-items: center;
    column-gap: 15px;
}

.navbar-buttons {
    display: flex;
    flex-wrap: nowrap;
    align-items: center;
    align-content: center;
    width: max-content;
}
#dropdown-menu4 {
    min-width: 150px;
}




/* Modal */
#modal {
    position: fixed;
    width: 50%; 
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    height: 300px;
    background: rgb(255, 255, 255);
    transition: transform 0.9s ease-in-out;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2); 
    z-index: 300; 
    padding: 20px;
    border-radius: $card-border-radius;
}

#modal.open {
    transform: translateY(0);
}

.all-notes {
    margin-bottom: 400px;
}

.inline-element {
    display: inline;
  }

.add-resources-button, .library-button {
    height: 35px;
    column-gap: 5px;
    padding: 1px 6px;
}

.linko-card .add-resources-button {
    height: 28px;
    width: fit-content;
    padding: 0px 5px;
}

.add-to-linko {
    height: fit-content;
    width: fit-content;
    padding: 5px 0px;
    position: relative;
    display: flex;
    align-items: center;
    white-space: nowrap;
}

.linko-card .add-to-linko {
    padding: 0px;
    height: 28px;
    display: inline-block;
    vertical-align: middle;
}
.linko-card .add-to-linko .button-text {
    font-size: 16px;
}

.add-dropdown-menu {
    width: 130px;
    background-color: #fff;
    border-radius: $button-border-radius;
    padding: 8px 8px 8px 8px;
    box-shadow: $card-box-shadow;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: space-between;
    position: absolute;
    top: 100%;
    right: 0;
    left: auto;
    z-index: 20;
}
    @media screen and (max-width: 1404px){
        .add-dropdown-menu {
        position: absolute;
        top: 100%;
        right: 0;
        left: auto;
        }
    }


.add-option {
    width: 100%;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    align-items: center;
    justify-content: flex-start;
    border: transparent;
    background-color: transparent;
    cursor: pointer;
    padding: 8px 8px 8px 8px;
    transition: all 0.2s ease-in-out;
}

.add-option:hover {
    background-color: #f3f3f3;
    border-radius: $button-border-radius;
    .add-type-icon {
        color: $primary-color;
    }
    .add-type {
        color: $primary-color;
    }
}   

.add-type-icon {
    color: $tertiary-font-color; 
    width: 20px;
    height: 20px;
    margin-right: 8px;
    transition: all 0.2s ease-in-out;
}

.add-type {
    height: 25px;
    font-size: 16px;
    font-weight: 400;
    color: $secondary-font-color;
    align-self: flex-start;
    text-align: left;
    line-height: 150%;
    transition: all 0.2s ease-in-out;
}

.add-type-tips {
    color: $secondary-font-color;
    align-self: flex-start;
    text-align: left;
    width: 100%;
    font-size: 12px !important;
    font-weight: 400;
    padding-top: 4px;
}

.add-hr {
    margin: 0px 20px 0px 20px;
    border: 0.5px solid #f5f5f5;
}

.dropdown-content {
    height: fit-content;
    padding-left: 10px !important;
    padding-right: 10px !important;
    border-radius: $card-border-radius !important;
}

/* Burger menu */
.burger-menu {
    font-size: 24px;
}

/* Landing page navbar */
.landing-page-actions {
    display: flex;
    flex-wrap: nowrap;
    align-items: center;
    justify-content: flex-end;
    column-gap: 10px;
    width: 100%;
    height: 100%;
}
.landing-page-actions .login-button {
    height: 35px;
    width: fit-content;
    background-color: transparent;
    color: $secondary-font-color;
    display: flex;
    flex-wrap: nowrap;
    align-items: center;
    align-content: center;
    font-size: large;
    font-weight: 400;
    border: 2px solid $border-normal;
    padding: 7px 15px;
    font-size: 14px;
    border-radius: $button-border-radius;
    &:hover {
        background-color: $grey-background;
    }
}



/* Footer */
#footer-container {
    width: 100%;
    display: flex;
    justify-content: center;
}
#footer {
    height: fit-content;
    width: 100%;
    display: inline-flex;
    padding: 15px;
    font-size: small;
    align-self: flex-end;
    justify-content: center;
}
    #footer .footer-link {
        color: #777777;
        padding-right: 16px;
    }
    #footer .footer-link:hover {
        text-decoration: underline;
    }
    #footer p {
        display: inline;
        width: fit-content;
        color: #636363;
        padding-right: 16px;
    }
.hide-footer {
    display: none;
}

    #half-footer {
        height: fit-content;
        width: 100%;
        margin-top: 10px;
        display: inline-flex;
        padding: 5px;
        font-size: small;
        align-self: center;
        justify-content: center;
        border-top: 1px solid #f5f5f5;  
        @include respond-to (medium) {
            display: none;
        }
    }

    #half-footer .footer-link {
        color: $secondary-font-color;
        padding-right: 16px;
    }
    #half-footer .footer-link:hover {
        text-decoration: underline;
    }
    #half-footer p {
        display: inline;
        width: fit-content;
        color: $secondary-font-color;
        padding-right: 16px;
    }


#footer.landing-page-footer {
    display: none;
}

@media screen and (min-width: 1404px){
    .the-tabs #half-footer {
        display: none;
    }
}
