.explore-tab-content {
    @include flex-center;
    @include flex-column;
    width: 100%;
}

.explore-tab-content .title-three {
    height: 40px;
    width: 100%;
    margin-bottom: 20px;
    @include flex-start;
}

.explore-tab-content .feed-group {
    @include flex-start;
    @include flex-column;
    width: 100%;
    margin-bottom: 30px;
}

.explore-tab-content .feed-group .feed-group-title {
    @include flex-space-between;
    align-items: center; 
    width: 620px;
    margin-bottom: 8px;
    padding: 0 8px;
    
    @include respond-to (small) {
        width: 100%;
    }
}

.explore-tab-content .feed-group .feed-group-title .like-icon {
    font-size: 20px;
    color: $tertiary-font-color;
    justify-self: baseline;
    cursor: pointer;
    margin-top: 5px;
}

// .explore-tab-content .feed-group .feed-group-title p {
//     font-size: 1em;
//     font-weight: 600;
//     margin-left: 10px;
//     color: #636363;
// }

.explore-tab-content .feed-group .feed-group-title .followee-name{
    cursor: pointer;
    font-weight: 600;
    color: $primary-font-color;

    &:hover{
        color: $primary-color;
    }
}

.feed-group .feed-group-title .sb-avatar__text span{
    font-size: 12px !important;
}

.feed-action-detail {
    display: flex;
    align-items: center;
    
    p {
        font-size: 16px;
        font-weight: 400 !important;
        color: $secondary-font-color;
        margin-left: 2px;
    }

    span {
        margin-left: 8px;
        font-size: 14px;
        font-weight: 400 !important;
        color: $tertiary-font-color;
    }
}