.username-dropdown {
    height: fit-content;
    padding: 5px 0px;
    display: flex;
    position: relative;

    .navbar-user-name {
        @include flex-center;
        height: 36px;
        padding: $spacing-xs $spacing-md;
        border-radius: $button-border-radius;
        border: 2px solid $border-normal;
        font-size: $primary-text-size;
        font-weight: $font-weight-regular;
        cursor: pointer;
        column-gap: $spacing-xs;
        transition: all $transition-fast;
        box-shadow: none;
        position: relative;
        overflow: hidden;
        // background-color: $grey-background;
        color: $secondary-font-color;
        transition: all $transition-normal;
        
        &:hover {
            background-color: darken($grey-background, 5%);
        }
    }
}

.username-dropdown .notification-badge {
    height: 10px;
    width: 10px;
    position: absolute;
    top: 2px;
    right: -2px;
    background-color: $badge-color;
    border-radius: 50%;
}
.username-dropdown .notification-count {
    height: 16px;
    width: 16px;
    border-radius: 50%;
    background-color: #eeeeee;
    color: #636363;
    font-size: 10px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.name-dropdown-menu {
    height: fit-content;
    width: fit-content;
    min-width: 160px;
    padding: 8px;
    position: absolute;
    top: 100%;
    right: 0;
    background-color: $white;
    border-radius: $button-border-radius;
    box-shadow: $card-box-shadow;
    z-index: 20;
}

.dropdown-item.setting, .logout{
    width: 100%;
    height: 40px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    column-gap: 10px;
    padding: 0 10px;
    font-size: 16px;
    font-weight: 400;
    cursor: pointer;
    &.sidebar-toggle {
        height: 50px;
        p {
            display: flex;
            flex-direction: column;
            align-items: flex-start;
            flex: 1;
            white-space: nowrap;
        }
        span {
            font-size: 10px;
        }
    }

    p {
        font-size: 16px;
        color: $secondary-font-color;
        white-space: nowrap;
    }
    &:hover p {
        color: $primary-color;
    }
    svg {
        height: 20px;
        width: 20px;
        color: $tertiary-font-color;
    }
    &:hover svg {
        color: $primary-color;
    }
}

    .dropdown-item.setting .notification-count {
        height: 16px;
        width: 16px;
        border-radius: 50%;
        background-color: #eeeeee;
        color: #636363;
        font-size: 10px;
        display: flex;
        align-items: center;
        justify-content: center;
    }
    .dropdown-item.setting:hover {
        background-color: #f3f3f3;
        border-radius: $button-border-radius;
    }

.notification-count {
    height: 16px;
    width: 16px;
}
.dropdown-item.setting:hover .notification-count {
    background-color: #ffffff;
    color: #636363;
}

.logout {
    &:hover {
        background-color: #f3f3f3;
        border-radius: $button-border-radius;
        svg, p {
            color: #fb4242;
        }
    }
}