@import '../../variables';

.page-container.subject-page {
    @include respond-to(small) {
        margin-top: 0;
    }
    .add-note-card-button {
        @include respond-to(small) {
            padding: 0px;
            margin-top: 10px;
            margin-bottom: 20px;
        }
    }
}

.page-container.subject-page .page-right{
    position: relative;
    @include respond-to (small) {
        width: 100%;
    }
}


.subject-page-scroll {
    width: 320px;
    height: fit-content;
    padding: 0 10px;
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    justify-content: flex-start;
    overflow-x: visible;
    row-gap: 15px;
    @include hide-scrollbar;
}   
    @media (max-width: 1024px) {
        .subject-page-scroll {
            height: fit-content;
            overflow: visible;
        }
    }
    @media screen and (max-width: 900px) {
        .subject-page-scroll {
            width: 100%;
            padding: 10px 20px 0px 20px;
        }
    }

.subject-resources {
    width: 560px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
}

@media (max-width: 670px) {
    .subject-resources {
        width: 100%;
        padding: 0px;
    }
}


.subject-rel {
    @include flex-column;
    width: 100%;
    height: fit-content;
    overflow: visible;
}
.subject-rel-title {
    width: 100%;
    display: flex;
    flex-wrap: nowrap;
    align-items: center;
    justify-content: flex-start;
    font-size: 16px;
    font-weight: 600;
    color: $primary-color;
    background-color: $light-background;
    padding: 10px 0 5px 0;
    z-index: 99;
    margin: 10px 0;
}

.subject-rel h3 {
    font-size: 13px;
    font-weight: 400;
    color: $tertiary-font-color;
    margin: 10px 0 0 5px;
}   

.subject-rel-list {
    @include flex-start;
    width: 100%;
    height: fit-content;
    flex-wrap: wrap;
    column-gap: 8px;
    row-gap: 8px;
}
.subject-rel-list.knowledge-tree-list {
    justify-content: center;
    .current-subject {
        background-color: $primary-color;
        color: #fff;
        a {
            color: #fff !important;
            font-weight: 600;
        }
    }
}
.subject-rel .show-sibling-button {
    width: 100%;
    display: flex;
    justify-content: center;
    button {
        color: $secondary-font-color;
        &:hover {
            color: $primary-color;
        }
    }
}

.knowledge-tree-triangle {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 5px 0;
}

.subject-rel-list .show-button {
    color: $secondary-font-color;
    &:hover {
        color: $primary-color;
    }
}

.leaderboard-user-container {
    @include flex-start;
    align-items: center;
    padding: 10px 5px 10px 10px;
    column-gap: 10px;
}
.leaderboard-user-container .follow-icon {
    cursor: pointer;
    margin-left: auto;
    color: $tertiary-font-color;
    &:hover {
        color: $primary-color;
    }
}

.leaderboard-user-name {
    font-size: $primary-text-size;
    font-weight: $username-text-weight;
    color: $secondary-font-color;
    cursor: pointer;
    &:hover {
        color: $primary-color;
    }
}

.leaderboard-user-score {
    font-size: $secondary-text-size;
    font-weight: $primary-text-weight;
    color: $tertiary-font-color;
}