.note-page-container {
    @include flex-column;
    align-items: center;
    width: 100%;
    height: fit-content;
    justify-content: center;
    row-gap: 20px;
    @include respond-to(small) {
        row-gap: 10px;
        width: 100%;
        padding: 0px 10px;
    }
}

.note-page-header {
    width: 620px;
    height: 40px;
    padding-top: 10px;
    padding-left: 10px;
    position: relative;
    display: flex;
    align-items: center;
    @include respond-to(x-large) {
        column-gap: 10px;
        position: inherit;
        height: fit-content;
        padding-left: 0;
    }
    @include respond-to(medium) {
        margin-top: 10px;
    }
    @include respond-to(small) {
        width: 100%;
    }
}

.note-page-header .back-button-wrapper {
    @include respond-to(x-large) {
        display: inherit;
        margin: 0;
    }
}

.note-page-title.subject-name {
    margin-top: 8px;
    font-weight: 500;
    @include respond-to(x-large) {
        margin-top: 0px;
    }

    span {
        font-weight: 600;
        &:hover {
            cursor: pointer;
            color: $primary-color;
        }
    }
}

.note-page-tabs {
    @include flex-start;
    align-items: center;
    width: 620px;
    margin-top: 10px;
    @include respond-to(small) {
        width: 100%;
        padding-left: 0;
    }
    .tab-button {
        font-size: 18px;
        color: $secondary-font-color;
        font-weight: 600;
    }
}

.note-page-container .cards-container {
    padding-right: 0px;
}