@import '../../Stylesheets/_variables.scss';

.summary-card {
    margin: 0px 0px 15px 5px;
    width: 620px;
    padding: 15px 20px;
    background-color: $tertiary-color;
    border-radius: 8px;
    border-left: 4px solid $primary-color;

    &.subject-summary {
        margin: 15px 0px 15px 0px;

        @include respond-to(small) {
            width: calc(100% - 20px);
            margin: 15px 10px;
        }
    }

    @include respond-to(small) {
        width: 100%;
        margin-left: 0px;
        margin-right: 0px;
    }

    .summary-header {
        display: flex;
        align-items: center;
        margin-bottom: 5px;
        gap: 8px;
        color: $secondary-font-color;
        font-weight: 500;

        .summary-icon {
            font-size: 12px;
            color: $secondary-font-color;
        }

        .summary-title {
            font-size: 14px;
        }
    }

    .summary-content {
        color: #4a4a4a;
        line-height: 1.6;
        font-size: 15px;

        h3 {
            font-size: 15px;
            font-weight: normal;
            margin: 0 0 15px 0;
            color: #4a4a4a;
        }

        h4 {
            font-size: 15px;
            font-weight: normal;
            margin: 20px 0 10px 0;
            color: #4a4a4a;
        }

        p {
            margin: 0 0 10px 0;
        }

        ol {
            margin: 0;
            padding-left: 20px;

            li {
                margin-bottom: 5px;
                padding-left: 5px;
            }
        }

        ul {
            margin: 0;
            padding-left: 20px;
            list-style-type: none;

            li {
                margin-bottom: 5px;
                padding-left: 5px;

                &::before {
                    content: "-";
                    position: absolute;
                    margin-left: -15px;
                }
            }
        }
    }

    .summary-expand-button {
        display: flex;
        align-items: center;
        gap: 5px;
        color: $secondary-font-color;
        font-size: 14px;
        font-weight: 500;
        background: none;
        border: none;
        padding: 15px 0 0 0;
        cursor: pointer;
        margin-top: 5px;

        &:hover {
            text-decoration: underline;
        }

        svg {
            margin-top: 1px;
        }
    }
} 