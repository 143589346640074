// 导入变量
@import './variables';

// Notion风格的字体系统 - 仅字体系列设置

// ===== 标题字体 =====
h1, .h1 {
  font-family: $font-family-sans;
}

h2, .h2 {
  font-family: $font-family-sans;
}

h3, .h3 {
  font-family: $font-family-sans;
}

// ===== 正文字体 =====
p, .p {
  font-family: $font-family-sans;
}

// ===== 引用块字体 =====
blockquote, .blockquote {
  font-family: $font-family-sans;
}

// ===== 代码样式字体 =====
code, .code {
  font-family: $font-family-mono;
}

pre, .pre {
  font-family: $font-family-mono;
  
  code {
    font-family: $font-family-mono;
  }
}

// ===== 列表字体 =====
ul, ol, li {
  font-family: $font-family-sans;
}

// ===== 字体类选择器 =====
.font-sans-text {
  font-family: $font-family-sans;
}

.font-serif-text {
  font-family: $font-family-serif;
}

.font-mono-text {
  font-family: $font-family-mono;
}

// ===== 字体风格选择器 =====
// 中文主要字体
.font-pingfang {
  font-family: 'PingFang SC', 'Hiragino Sans GB', 'Microsoft YaHei', sans-serif;
}

.font-hiragino {
  font-family: 'Hiragino Sans GB', 'PingFang SC', 'Microsoft YaHei', sans-serif;
}

.font-yahei {
  font-family: 'Microsoft YaHei', 'PingFang SC', 'Hiragino Sans GB', sans-serif;
}

// 英文主要字体
.font-inter {
  font-family: 'Inter', -apple-system, BlinkMacSystemFont, 'Segoe UI', sans-serif;
}

.font-helvetica {
  font-family: 'Helvetica Neue', Helvetica, Arial, sans-serif;
}

// 特殊用途字体
.font-code {
  font-family: $font-family-mono;
} 