.resource-progress{
    height: 18px;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    column-gap: 10px;

    .completed-progress {
        color: $tertiary-font-color;
        font-size: 14px;
        display: flex;
        align-items: center;
        justify-content: center;
        column-gap: 4px;
        cursor: pointer;
    }
    .note-count {
        flex-shrink: 0;
        min-width: 34px;
        text-align: left;
        vertical-align: middle;
        line-height: 14px;
        color: $tertiary-font-color;
        font-size: 14px;
        font-weight: 500;
        font-style: normal;
    }
}

.resource-progress .resource-check-icon {
    color: $primary-color;
    font-size: 14px;
    line-height: 14px;
}

.progress-portion-wrapper {
    height: 100%;
    width: 65px;
    display: flex;
    align-items: center;
    padding: 4px;
    border-radius: 4px;
    cursor: pointer;
    transition: background-color 0.2s ease;

    &:hover {
        background: #f2f2f2;
    }
}

.progress-portion {
    font-size: 14px;
    color: $tertiary-font-color;
    display: flex;
    align-items: center;
    column-gap: 4px;
    height: fit-content;
    font-weight: 500;
}

.progress-bar {
    height: 8px;
    width: 160px;
    border: 1px solid #dedede80;
    border-radius: 7px;
    position: relative;
    background-color: #ffffff;
}

.progress-bar .progress-bar-fill {
    height: 100%;
    background: $secondary-color;
    border-radius: 7px;
    position: relative;
}

.progress-drag-dot {
    width: 14px;
    height: 14px;
    background: $primary-color;
    border-radius: 50%;
    position: absolute;
    right: -7px;
    top: 50%;
    transform: translateY(-50%);
    cursor: grab;
    box-shadow: 0 0 4px rgba(0, 0, 0, 0.2);
    display: block;
}

.progress-drag-dot:active {
    cursor: grabbing;
}

.resource-progress .set-progress-form {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    align-items: center;
}

.resource-progress .progress-input {
    width: 50px;
    height: 16px;
    border: none;
    border-radius: 7px;
    background: #f2f2f2;
    margin: 0 3px;
    color: #636363;
}

.resource-progress .progress-input:focus {
    outline: none;
}

.resource-progress p {
    font-size: 14px;
}

.resource-progress button {
    background: none;
    border: none;
    color: #626262;
    font-size: 14px;
    cursor: pointer;
}

.resource-progress button:hover {
    color: $primary-color;
}

.set-progress-form .check-finished {
    display: flex;
    align-items: center;
    color: #a3a3a3;
}

.set-progress-form .check-finished:hover {
    color: $primary-color;
}