@import url('https://fonts.googleapis.com/css2?family=Inter:wght@300;400;500;600;700&display=swap');

// Core styles and variables - these must come first
@import './_variables.scss';
@import './_mixins.scss';
@import './_utilities.scss';
@import './_typography.scss';
@import './_buttons.scss';
@import './_cards.scss';
@import './_titles.scss';

// Import main style
@import "./Style.scss";

// ==== GLOBAL STYLES ====

*, *::before, *::after {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

html {
  scroll-behavior: smooth;
  background-color: $light-background;
  min-height: 100%;
}

body {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-size: $primary-text-size;
  line-height: $line-height-normal;
  color: $primary-font-color;
  background-color: $light-background;
  transition: background-color $transition-normal;
  min-height: 100vh;
}

#root {
  background-color: $light-background;
  min-height: 100vh;
}

// ==== FOCUS STYLES ====

:focus {
  outline: none;
}

:focus-visible {
  outline: none;
}

// ==== TEXT SELECTION ====

::selection {
  background-color: $tertiary-color;
  color: $primary-color;
}

// ==== CURSOR STYLES ====

input, textarea, [contenteditable="true"] {
  caret-color: $primary-color;
}

// ==== SCROLL BAR STYLING ====

::-webkit-scrollbar {
  width: 8px;
  height: 8px;
}

::-webkit-scrollbar-track {
  background: #f1f1f1;
  border-radius: 10px;
}

::-webkit-scrollbar-thumb {
  background: $grey-background;
  border-radius: 10px;
  
  &:hover {
    background: #a1a1a1;
  }
}

// ==== LAYOUT STYLES ====

#all-content {
  min-height: calc(100vh - 120px);
  background-color: $light-background;
}

#mainContent {
  min-height: calc(100vh - 120px);
  display: flex;
  margin-top: 70px;
  justify-items: center;
  align-items: flex-start;
  justify-content: center;
  
  @include respond-to(medium) {
    margin-top: 60px;
  }
  
  @include respond-to(small) {
    width: 100vw;
    padding: 0;
  }
}

#minContent {
  margin-top: 0 !important;
}

.cards-container {
  @include flex-start;
  @include flex-column;
  width: 100%;
  flex-wrap: nowrap;
  row-gap: $spacing-md;
  column-gap: $spacing-md;
  
  @include respond-to(small) {
    padding: $spacing-sm 0;
    width: 100%;
  }
}

// ==== LOADER STYLES ====

.loader-container {
  @include flex-center;
  width: 100%;
  height: 100%;
  min-height: 100px;
  position: relative;
  
  &::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(255, 255, 255, 0.15);
    border-radius: $card-border-radius;
    z-index: 0;
  }
  
  & > * {
    position: relative;
    z-index: 1;
  }
}

.cards-container .the-loader span {
  height: 30px !important;
  width: 30px !important;
}

.tab-content-loader {
  @include flex-center;
  width: 600px;
  height: 100px;
  padding: $spacing-md 0;

  @include respond-to(small) {
    width: 100%;
  }
}

.tab-content-loader .the-loader {
  width: 30px !important;
  height: 30px !important;
}

.library-tabs .tab-content-loader {
  @include flex-center;
  width: 100%;
  height: 200px;
}

.page-loading {
  width: 100vw;
  height: 100vh;
  @include flex-center;
  background-color: $light-background;
}

.add-note-card .loader-container {
  width: 100%;
  height: 250px;
  display: flex;
  align-items: center;
  justify-content: center;
}


// Specific textarea styles to ensure no borders when focused
textarea, input {
  &:focus, &:active, &:hover {
    outline: none !important;
  }
}

// ==== ANIMATIONS ====

@keyframes fadeIn {
  from { opacity: 0; }
  to { opacity: 1; }
}

@keyframes slideUp {
  from { transform: translateY(10px); opacity: 0; }
  to { transform: translateY(0); opacity: 1; }
}

@keyframes pulse {
  0% { transform: scale(1); }
  50% { transform: scale(1.05); }
  100% { transform: scale(1); }
}

.fade-in {
  animation: fadeIn $transition-normal;
}

.slide-up {
  animation: slideUp $transition-normal;
}

// ==== MISC ====

a {
  color: $primary-color;
  text-decoration: none;
  transition: color $transition-fast;
  
  &:hover {
    color: darken($primary-color, 10%);
    text-decoration: none;
  }
}

img {
  max-width: 100%;
  height: auto;
}

// ==== IMPORTED COMPONENTS OVERRIDES ====

// React-toastify custom container styles
.Toastify__toast-container {
  padding: $spacing-sm;
}

// Placeholder styles when content is loading
.skeleton-loading {
  position: relative;
  background-color: #f0f0f0;
  border-radius: $input-border-radius;
  overflow: hidden;
  
  &::after {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: linear-gradient(90deg, transparent, rgba(255, 255, 255, 0.4), transparent);
    animation: skeletonShimmer 1.5s infinite;
  }
}

@keyframes skeletonShimmer {
  0% { transform: translateX(-100%); }
  100% { transform: translateX(100%); }
}

/* Note Card */
@import './ComponentsStyles/NoteCard/NoteCard';
@import './ComponentsStyles/NoteCard/AddNoteCard';
@import './ComponentsStyles/NoteCard/NoteCardResource';
@import './ComponentsStyles/NoteCard/NoteCardSubject';
@import './ComponentsStyles/NoteCard/RichEditor';
@import './ComponentsStyles/NoteCard/FocusMode';

@import './ComponentsStyles/NoteCard/CardActions';

/* Resource Card */
@import './ComponentsStyles/ResourceCard/ResourceCard';
@import './ComponentsStyles/ResourceCard/ResourceProgress';
@import './ComponentsStyles/SummaryCard';

/* Modals & Toast & Tooltip */
@import './ComponentsStyles/Modals&Toast&Tooltip/Tooltip';
@import './ComponentsStyles/Modals&Toast&Tooltip/InfoModal';
@import './ComponentsStyles/Modals&Toast&Tooltip/AddModals';
@import './ComponentsStyles/Modals&Toast&Tooltip/WholePageModal';
@import './ComponentsStyles/Modals&Toast&Tooltip/AIFeedbackModal';
@import './ComponentsStyles/Modals&Toast&Tooltip/InputModals';

/* Other */
@import './ComponentsStyles/TheTabs';
@import './ComponentsStyles/Explore';
@import './ComponentsStyles/KnowledgeGraph';
@import './ComponentsStyles/Calendar';
@import './ComponentsStyles/SubjectTag';
@import './ComponentsStyles/Joyride';
@import './ComponentsStyles/LikeButton';

/* Navbar & Footer */
@import './Navbar/NavbarAndFooter';
@import './Navbar/NavbarName';
@import './Navbar/Search';

/* Pages */
@import './PagesStyles/AuthPage';
@import './PagesStyles/LandingPage';
@import './PagesStyles/HomePage';
@import './PagesStyles/Heatmap';
@import './PagesStyles/PolicyPage';
@import './PagesStyles/PublicProfilePage';
@import './PagesStyles/Welcome';
@import './PagesStyles/NotePage';

// ResourcePage
@import './PagesStyles/ResourcePage/ResourcePage';
@import './PagesStyles/ResourcePage/ArticleReader';
@import './PagesStyles/ResourcePage/BookTabs';
@import './PagesStyles/ResourcePage/ChatGPTStyle';
@import './PagesStyles/ResourcePage/ResourcePageResource';
// MyLibrary
@import './PagesStyles/MyLibrary/MyLibrary';
@import './PagesStyles/MyLibrary/ResourceFilter';
@import './PagesStyles/MyLibrary/LibraryCardsResponsive';
// Subject Page
@import './PagesStyles/SubjectPage/SubjectPage';
@import './PagesStyles/SubjectPage/SubjectPageHeader';
@import './PagesStyles/SubjectPage/EditCusLabel';
// Account Pages
@import './PagesStyles/AccountPages/ProfilePage';
@import './PagesStyles/AccountPages/SettingPage';
@import './PagesStyles/AccountPages/NotificationPage';

/* Privacy Policy Page Styles */
.privacy-policy {
  max-width: 800px;
  margin: 0 auto;
  padding: 20px;
  
  h1 {
    font-size: 28px;
    font-weight: 600;
    margin-bottom: 10px;
  }
  
  h2 {
    font-size: 22px;
    font-weight: 500;
    margin-top: 30px;
    margin-bottom: 15px;
    padding-bottom: 5px;
    border-bottom: 1px solid #eee;
  }
  
  h3 {
    font-size: 18px;
    font-weight: 500;
    margin-top: 20px;
    margin-bottom: 10px;
  }
  
  p {
    margin-bottom: 15px;
    line-height: 1.5;
  }
  
  ul {
    margin-bottom: 20px;
    padding-left: 20px;
    
    li {
      margin-bottom: 8px;
      line-height: 1.5;
      position: relative;
      
      span {
        font-weight: 500;
      }
    }
  }
  
  a {
    color: #4285f4;
    text-decoration: none;
    
    &:hover {
      text-decoration: underline;
    }
  }
  
  .policy-table-of-contents {
    background-color: #f8f9fa;
    padding: 15px 20px;
    border-radius: 8px;
    margin: 20px 0 30px 0;
    
    h2 {
      font-size: 18px;
      margin-top: 0;
      margin-bottom: 10px;
      border-bottom: none;
    }
    
    ul {
      margin-bottom: 0;
      
      li {
        margin-bottom: 5px;
        
        a {
          text-decoration: none;
          
          &:hover {
            text-decoration: underline;
          }
        }
      }
    }
  }
  
  [id] {
    scroll-margin-top: 20px;
  }
}

