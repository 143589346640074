.landing-page {
    width: 100%;
    height: 100vh;
    height: fit-content;
    overflow: visible;
    position: relative;
    background: linear-gradient(135deg, $tertiary-color 0%, #c2f0d8 50%, #9fe4c1 100%);
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    padding: 15vh 10vw 10vw 10vw;
    margin-top: -70px;
}
    @media screen and (max-width: 1404px){
        .landing-page {
            padding: 2rem 10vw 10vw 10vw;
        }
    }
    @media screen and (max-width: 670px){
        .landing-page {
            min-height: calc(100vh - 108px);
            padding: 2rem 20px 20px 20px;
        }
        
    }
    @media screen and (max-width: 1404px){
        .landing-page {
            min-height: calc(100vh - 60px);
        }
    }
.landing-page .hero {
    height: 100%;
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: flex-start;
    column-gap: 5%;
    @include respond-to(medium){
        flex-direction: column;
        row-gap: 40px;
    }
}

.landing-page .hero .hero-text {
    width: 40%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    padding-top: 5vh;
    @include respond-to(medium){
        width: 100%;
    }
}

.landing-page .hero p {
    font-size: 1.5rem;
    font-weight: 400;
    color: #636363;
    text-align: left;
    margin-bottom: 80px;
}
    @media screen and (max-width: 900px){
        .landing-page .hero p {
        font-size: 1rem;
        }
    }

.landing-page .hero .sign-up {
    height: 45px;
    width: fit-content;
    background-color: $secondary-color;
    color: #fff;
    display: flex;
    flex-wrap: nowrap;
    align-items: center;
    align-content: center;
    justify-content: center;
    font-size: large;
    font-weight: 500;
    border: transparent;
    padding: 7px 15px;
    border-radius: 25px;
}
    @media screen and (max-width: 900px){
        .landing-page .hero .sign-up {
            height: 35px;
            font-size: 1rem;
        }
    }
.landing-page .hero .sign-up .arrow {
    margin-left: 8px;
    font-size: 1.25rem;
}
.landing-page .hero .sign-up:hover {
    box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.2);
}

//     .landing-page .hero .hero-img {
//     width: 60%;
//     height: 100%;
//     margin-top: 5vh;
//     position: relative;
//     @include respond-to(medium){
//         width: 100%;
//         min-height: 800px;
//         height: fit-content;
//     }
// }

// .landing-page .hero .hero-img .hero-img-subject {
//     width: 75%;
//     object-fit: contain;
//     position: absolute;
//     top: 0;
//     left: 0;
//     box-shadow: 0 0 15px 0 #aee1ca;
//     z-index: 1;
//     border-radius: 15px;
//     overflow: hidden;
//     @include respond-to(medium){
//         width: 90%;
//     }
// }

// .landing-page .hero .hero-img .hero-img-note {
//     width: 50%;
//     position: absolute;
//     right: 0;
//     margin-top: 30%;
//     object-fit: contain;
//     z-index: 2;
//     border-radius: 15px;
//     overflow: hidden;
//     box-shadow: 0 0 15px 0 #aee1ca;
//     @include respond-to(medium){
//         width: 60%;
//     }
//     @include respond-to(small){
//         width: 70%;
//         top: 20%;
//     }
// }

.landing-page .hero .hero-img .hero-img-resource {
    width: 50%;
    position: absolute;
    top: 0;
    left: 0;
    object-fit: contain;
    z-index: 3;
    border-radius: 15px;
    overflow: hidden;
    box-shadow: 0 0 15px 0 #aee1ca;
}
