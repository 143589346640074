.privacy-policy {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    padding: 0 25vw;
    margin-top: 30px;
}
    @media screen and (max-width: 1404px) {
        .privacy-policy {
            padding: 0 10vw;
            margin-top: 10px;
        }
    }

.privacy-policy h1 {
    font-size: 2.5rem;
    margin-bottom: 1rem;
}
.privacy-policy h2 {
    font-size: 2rem;
    margin-bottom: 1rem;
    margin-top: 1rem;
}
.privacy-policy h3 {
    font-size: 1.5rem;
    margin-bottom: 1rem;
}

.privacy-policy p {
    font-size: 1rem;
    margin-bottom: 1.5rem;
}

.privacy-policy span {
    font-weight: bold;
}

.privacy-policy a {
    color: $primary-color;
}

.privacy-policy li {
    font-size: 1rem;
    margin-bottom: 0.5rem;
    margin-left: 1rem;
    list-style-type: disc;
}