/* purgecss start ignore */
@import 'variables';
@import 'mixins';

// Headings
.title-one {
    color: $primary-color;
    font-size: $primary-title-size;
    font-weight: $font-weight-semibold;
    line-height: $line-height-tight;
    letter-spacing: -0.2px;
    @include flex-Vcenter;
    justify-content: flex-start;
    margin-bottom: $spacing-md;
}

.title-two {
    color: $secondary-font-color;
    font-size: $secondary-title-size;
    font-weight: $font-weight-semibold;
    line-height: $line-height-tight;
    letter-spacing: -0.1px;
    @include flex-Vcenter;
    justify-content: flex-start;
    margin-bottom: $spacing-sm;
}

.title-three {
    color: $secondary-font-color;
    font-size: $tertiary-title-size;
    font-weight: $font-weight-medium;
    line-height: $line-height-normal;
    margin-bottom: $spacing-sm;
}

// Text styles
.text-primary {
    font-size: $primary-text-size;
    color: $primary-font-color;
    line-height: $line-height-normal;
    font-weight: $font-weight-regular;
}

.text-secondary {
    font-size: $secondary-text-size;
    color: $secondary-font-color;
    line-height: $line-height-normal;
    font-weight: $font-weight-regular;
}

.text-tertiary {
    font-size: $tertiary-text-size;
    color: $tertiary-font-color;
    line-height: $line-height-normal;
    font-weight: $font-weight-regular;
}

// Text modifiers
.text-bold {
    font-weight: $font-weight-bold;
}

.text-semibold {
    font-weight: $font-weight-semibold;
}

.text-medium {
    font-weight: $font-weight-medium;
}

.text-light {
    font-weight: $font-weight-light;
}

.text-italic {
    font-style: italic;
}

// Link style
.link-text {
    color: $primary-color;
    text-decoration: none;
    transition: color $transition-fast;
    
    &:hover {
        text-decoration: underline;
        color: darken($primary-color, 10%);
    }
}
/* purgecss end ignore */