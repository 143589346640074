@import '../../../Stylesheets/_variables.scss';

.conversation {
    font-family: Arial, sans-serif;
}

.message-user p, .message-assistant p, .message-system p {
    margin: 0;
    padding: 10px;
    border-radius: $button-border-radius;
    width: fit-content;
}

.message-user {
    text-align: left;
}

.message-user p {
    background-color: transparent;
    font-weight: bold;
    color: $primary-color;
}

.message-assistant {
    text-align: right;
}

.message-assistant p {
    background-color: transparent;
    text-align: left;
    color: $primary-font-color;
}

/* .message-system p {
    background-color: #f0f0f0;
} */