

.add-to-linko-modal {
  width: 620px;   
  max-height: 800px; 
  display: flex;
  flex-direction: column;
  align-items: center;
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: rgba(255, 255, 255, 0);
  z-index: 666;
  @include respond-to(small) {
    width: 100%;
    padding: 0 20px;
  }
}

.add-modal {
    height: fit-content;
    width: 100%;
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    align-items: flex-start;
    justify-items: space-between; 

    .modal-header {
        margin-bottom: 0px !important;
    }
}

.add-type-title{
    font-size: $large-header-text-size;
    font-weight: 600;
    color: $primary-font-color;
}

.add-type-subtitle {
    margin-left: 20px;
    font-size: 14px;
    color: rgb(155, 155, 155);
    align-self: center;
    margin-bottom: 25px;
}

.add-form {
    width: 100%;
}

.add-book-form {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    justify-content: flex-end;
}

.search-books{
    width: 100%;
    height: 52px;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    margin-bottom: 10px;
    border: 2px solid $border-light;
    border-radius: $input-border-radius;
    padding: 16px;
    margin-top: 15px;
    font-size: 16px;
    color: $secondary-font-color;
}

.search-books:focus {
    outline: none !important;
}

.textarea-title {
    font-size: 16px;
    font-weight: 500;
    margin-bottom: 10px;
}

.add-link-tip {
    width: 100%;
    display: flex;
    justify-content: flex-start;
    color: $primary-color;
    font-size: 16px;
}

.add-with-link {
    display: flex;
    padding: 6px 16px;
    justify-content: center;
    align-items: center;
    border:#48c78e00;
    border-radius: $button-border-radius;
    background: $secondary-color;
    color: $white;
    font-size: 16px;
    font-weight: 500;
}

.no-link-for-add {
    display: flex;
    padding: 6px 16px;
    justify-content: center;
    align-items: center;
    border:#48c78e00;
    border-radius: $button-border-radius;
    background: var(--basic-black-60, #C1C1C1);
    color: var(--basic-white-100, #FFF);
    font-size: 16px;
    font-weight: 500;
}

.add-resoruce-input {
    width: 100%;
    border: 1px solid $grey-background;
    border-radius: $input-border-radius;
    margin-bottom: 20px;
    padding: 15px;
    align-items: flex-start;
}

.add-resoruce-input.note-title-input {
    height:60px;
    resize: none;
}

.add-resoruce-input.note-content-input{
    height: 60%;
    resize: none; 
}




.book-search-results {
    height: 400px;
    width: 100%;
    overflow-y: auto;
    padding-right: 8px;
}

.book-search-results::-webkit-scrollbar {
    width: 8px;
}
.book-search-results:hover::-webkit-scrollbar {
    width: 8px;
}
.book-search-results::-webkit-scrollbar-thumb {
    background: #c4c4c4;
    border-radius: 8px;
}
.book-search-results:hover::-webkit-scrollbar-thumb {
    background: #c4c4c4;
    border-radius: 8px;
}
.book-search-results::-webkit-scrollbar-thumb:hover {
    background: #a0a0a0;
}

.book-search-results .search-loader {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
    width: 100%;
}

.book-search-result {
    width: 100%;
    height: 120px;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: flex-start;
    padding: 12px 16px;
    border-radius: $card-border-radius;
}

.book-search-result:hover {
    background-color: rgb(240, 240, 240);
    cursor: pointer;
}

.search-book-img {
    width: 65px;
    height: 95px;
    margin-right: 20px;
}

.search-book-info {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    height: 100%;
}

.search-book-title {
    max-height: 3em;
    overflow: hidden;
    color: var(--basic-black-90, #343434);
    font-size: 18px;
    font-weight: 600;
    line-height: 150%;
    margin-bottom: 8px;
    white-space: wrap;
}

.search-book-author{
    color: var(--basic-black-80, #636363);
    font-size: 14px;
    font-weight: 400;
    line-height: 150%;
}


.linko-modal .search-results-resource {
    padding: 4px 10px;
    margin-bottom: 8px;
    &:hover {
        background-color: $grey-hover;
    }
}


.suggested-resources {
    height: 400px;
    overflow: auto;
    padding-right: 8px;
}

.linko-modal .link-new-resource {
    width: 100%;
    @include flex-start;
    align-items: center;
    column-gap: 20px;
    margin: 10px 0px 15px 0px;
}
.linko-modal .link-new-resource span {
    color: $secondary-font-color;
    font-weight: 500;
}
.linko-modal .link-new-resource .linko-button {
    width: fit-content;
}

.unlink-button {
    background-color: transparent;
    border: none;
    color: $secondary-font-color;
    cursor: pointer;
    font-size: 14px;
    margin-left: auto;
    font-weight: 500;
    padding-bottom: 10px;
}