.profile-page {
  width: 680px;
  margin: 0 auto;
  padding: 20px;
  @include respond-to (medium) {
    width: 100%;
    padding: 20px 40px;
  }
  @include respond-to (small) {
    padding: 20px 10px;
  }

  h1 {
    font-size: 30px;
    font-weight: 600;
    color: $primary-color;
    margin-bottom: 30px;
    padding-left: 20px;

    @include respond-to (small) {
      padding-left: 15px;
      font-size: 26px;
      margin-bottom: 15px;
    }
  }
}

.profile-page .tabs {
  display: flex;
  margin-bottom: 20px;
  gap: 20px;
  justify-content: flex-start;
  @media (max-width: 670px) {
    gap: 10px;
  }
}

.profile-page .tabs button {
  font-size: 16px;
  background: none;
  border: none;
  padding: 10px 20px;
  cursor: pointer;
  color: $secondary-font-color;
  font-weight: bold;
  @media (max-width: 670px) {
    padding: 10px 15px;
  }
}

.profile-page .tabs button.active {
  color: $primary-font-color;
  border-bottom: 2px solid $secondary-color;
}

.profile-page .user-list {
  width: 100%;
  display: flex;
  flex-direction: column;
}

.profile-page .user-item {
  display: flex;
  align-items: center;
  padding: 10px 0;
  height: 80px;

  @include respond-to (small) {
    padding-left: 10px;
  }
}

.profile-page .user-info {
  flex-grow: 1;
}

.profile-page .user-name {
  font-weight: 600;
  cursor: pointer;
  width: fit-content;
}

.profile-page .user-username {
  color: $tertiary-font-color;
  font-size: 0.9em;
  cursor: pointer;
  width: fit-content;
}

.profile-page .user-action {
  padding: 5px 15px;
  border-radius: 5px;
  border: 1px solid;
  cursor: pointer;
  font-size: 0.9em;
}

.profile-page .user-action.follow {
  background-color: white;
  color: $secondary-color;
  border-color: $secondary-color;
}

.profile-page .user-action.friends {
  background-color: #fff;
  color: $tertiary-font-color;
  border-color: $tertiary-font-color;
}
