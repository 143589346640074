

.notification-dot {
  position: absolute;
  top: 5px;
  right: 5px;
  width: 8px;
  height: 8px;
  background-color: $badge-color;
  border-radius: 50%;
}

.notifications-list {
  margin-left: 20px;
  @include respond-to(small) {
    margin-left: 15px;
  }
  h3 {
    color: $primary-font-color;
    font-weight: 600;
    font-size: 18px;
    margin-top: 20px;
    margin-bottom: 10px;
  }
}

.notification-item {
  padding: 10px 0;

  .user-name {
    font-weight: 600; 
    color: $secondary-font-color;

    &:hover {
      color: $primary-color;
    }
  }
}

.notification-content {
  padding: 5px;
  border-radius: 5px;
}

.notification-content.note {
  display: flex;
  align-items: flex-start;
}

.icon-note {
  margin-right: 10px;
  font-size: 20px;
}

.notification-content.resource {
  display: flex;
  align-items: center;
}

.notification-content.resource img {
  width: 60px;
  height: 90px;
  object-fit: cover;
  margin-right: 10px;
}

.notification-date {
  color: #999;
  font-size: 0.9em;
}

.request-item {
    height: 50px;
    margin: 15px 0;
    display: flex;
    align-items: center;
    justify-content: space-between;
    @media (max-width: 550px) {
    flex-direction: column;
    gap: 10px;
    align-items: flex-start;
    width: 100%;
  }

  .user-name {
    font-weight: 600;
    color: $secondary-font-color;

    &:hover {
      color: $primary-color;
    }
  }
}

.request-item .action-buttons {
  display: flex;
  gap: 10px;
}
 
.request-item .accept-button {
  background-color: $secondary-color;
  color: $white;
  border: none;
  padding: 5px 10px;
  border-radius: 5px;
  font-weight: 500;
}

.request-item .delete-button {
  background-color: transparent;
  color: $tertiary-font-color;
  border: none;
  padding: 5px 10px;
  border-radius: 5px;
  font-weight: 500;
}