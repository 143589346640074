  
  .info-modal-content {
    width: fit-content;
    min-width: 400px;
    max-width: 620px;
    height: fit-content;
    max-height: 80%;
    overflow-y: auto;
    padding: 24px;
    background: $white;  
    border-radius: $card-border-radius;
    box-shadow: $card-box-shadow;
    display: flex;
    flex-direction: column;
    align-items: center;
    row-gap: 20px;
    color: $secondary-font-color;
    @include respond-to(small) {
        min-width: 100%;
        margin: 0 15px;
    }


    p {
        font-size: $large-text-size;
        font-weight: $font-weight-regular;
        color: $secondary-font-color;
        margin-bottom: 15px;
    }

    .modal-buttons{
        display: flex;
        justify-content: center;
        column-gap: 10px;
    }

    .modal-header {
        width: 100%;
        display: flex;
        justify-content: center !important;
        align-items: center;
        column-gap: 10px; 
        h2 {
            font-size: $large-text-size;
            font-weight: $font-weight-semibold;
            color: $primary-font-color;
            text-align: center;
        }
    }

    .modal-body {
        width: 100%;
    }


    &.learned-users-modal{

        width: 400px;
        @include respond-to(small) {
            min-width: 100%;
            margin: 0 15px;
        }

        .user-section {
            margin-bottom: 10px;
            width: 100%;
    
            .user-list {
                display: flex;
                flex-wrap: wrap;
                gap: 10px;
                width: 100%;
    
                .user-item {
                    display: flex;
                    align-items: center;
                    justify-content: flex-start;
                    width: 100%;
                    height: 40px;
                }
    
                .user-name {
                    cursor: pointer;
                    font-weight: $font-weight-semibold;
                    padding: 2px 4px;
                    color: $secondary-font-color;
                    margin-left: 5px;
    
                    &:hover {
                        color: $primary-color;
                    }
                }
    
                button {
                    margin-left: auto;
                }
            }
        }
        .private-learned-user-count {
            width: 100%;
            height: 40px;
            @include flex-start;
            @include flex-Vcenter;
            font-size: $primary-text-size;
            font-weight: $font-weight-medium;
            color: $secondary-font-color;
        }
    }

}

