
.note-card-footer {
    @include flex-space-between;
    align-items: center;
    width: 100%;
    height: 50px;
    padding: 8px 15px 8px 20px;
    border-radius: $card-border-radius;
    border-top-left-radius: 0px;
    border-top-right-radius: 0px;
    cursor: pointer;
}
    .note-card-footer:hover {
        background-color: $grey-hover;
    }
@media (max-width: 670px) {
    .note-card-footer {
        width: 100%;
        padding: 1% 2%;
    }
}

    .note-page-card-footer .note-card-resource-author {
        margin-left: 30px;
    }

.note-card-resource {
    display: flex;
    flex-direction: row;
    align-items: center;
    width: auto;
    flex-grow: 1;
}
@media (max-width: 670px) {
    .note-card-resource {
        width: 100%;
        padding: 0px;
    }
}

.note-card-resource .resource-type-icon {
    flex-shrink: 0;
    overflow: hidden;
    margin-top: 5px;
    margin-bottom: 0px;
    margin-right: 3px;
}
@media (max-width: 670px) {
    .note-card-resource .icon {
        display: none;
    }
}

.note-card-resource-title {
    height: 24px;
    margin-left: 5px;
    max-width: 100%;
    font-size: 16px;
    font-weight: 500;
    color: $secondary-font-color;
    overflow: hidden;
}
@media (max-width: 670px) {
    .note-card-resource-title {
        width: 100%;
    }
}

.note-card-resource-author {
    color: $secondary-font-color;
    font-size: 14px;
    font-weight: 400;
    line-height: normal;
    text-align: right;
    max-width: 150px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}
@media (max-width: 670px) {
    .note-card-resource-author {
        display: none;
    }
}