.welcome-container{
    height: calc(100vh - 200px);
    display: flex;
    align-items: center;
}

.skip-tutorial {
    margin-top: 20px;
    margin-left: 10px;
    font-size: 12px;
    color: $tertiary-font-color;
}
.skip-tutorial .skip-button{
    color: $primary-color;
}

.tip-container {
    @include flex-column;
    justify-content: space-between;
    width: 500px;
    height: 540px;
    background-color: $secondary-color;
    border-radius: $card-border-radius;
    box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.2);
}

    @media screen and (max-width: 500px){
        .tip-container {
            width: 100%;
        }
    }

.tip {
    width: 100%;
    height: 100%;
    overflow: hidden;
}

.visual-demo {
    width: 100%;
    height: 55%;
    display: flex;
    justify-content: center;
    align-items: center;
}
.visual-demo img {
    width: 95%;
    height: 90%;
    object-fit: cover;
    object-position: bottom;
    border-radius: $card-border-radius;
}
.middle-tips .visual-demo img {
    object-fit: cover;
    object-position: top;
}
    @media screen and (max-width: 500px) {
        .middle-tips .visual-demo img {
            object-fit:cover;
        }
    }
    
.tip-text {
    width: 100%;
    height: 33%;
    padding: 24px;
}
    @media screen and (max-width: 500px){
        .tip-text {
            height: fit-content;
        }
        
    }
.middle-tips .tip-text {
    background-color: $white;
}
.tip-text h1 {
    font-size: 36px;
    font-weight: 500;
    margin-bottom: 3px;
}
.welcome .tip-text h1 {
    color: $white;
}
.middle-tips .tip-text h1 {
    color: $primary-font-color;
}
.tip-text h1 span {
    font-weight: 700;
}
.tip-text p {
    font-size: 18px;
    font-weight: 500;
    color: $white;
    line-height: 1.75;
    margin-bottom: 16px;
}
.middle-tips .tip-text p {
    color: $primary-font-color;
    font-weight: 400;
}


.tip-buttons {
    position: relative;
    width: 100%;
    height: 12%;
    border-radius: $card-border-radius;
    border-top-left-radius: 0%;
    border-top-right-radius: 0%;
}
.middle-tips .tip-buttons {
    background-color: $white;
}
.tip-button-next {
    position: absolute;
    right: 24px;
    bottom: 24px;
    height: 36px;
    border-radius: $button-border-radius;
    border: none;
    font-size: 16px;
    font-weight: 600;
    display: flex;
    align-items: center;
    padding: 5px 16px;
}
.tip-button-next .next-arrow {
    height: 18px;
    width: 18px;
    margin-left: 3px;
}

/* @media screen and (max-width: 670px){
    .tip-button-next, .tip-button-back {
        position: relative;
    }
} */

.welcome .tip-button-next {
    background-color: $white;
    color: $secondary-color;
}
.welcome .tip-button-next:hover {
    background-color: $grey-hover;
}
.welcome .tip-button-next .next-arrow {
    color: $secondary-color;
}
.middle-tips .tip-button-next {
    background-color: $secondary-color;
    color: $white;
}
.middle-tips .tip-button-next:hover {
    background-color: $secondary-color;
}
.middle-tips .tip-button-next .next-arrow {
    color: $white;
}

.tip-buttons .tip-button-back {
    position: absolute;
    left: 24px;
    bottom: 36px;
    border: none;
    font-size: 14px;
    font-weight: 400;
    color: $tertiary-font-color;
    background-color: transparent;
    display: flex;
    align-items: center;
    column-gap: 3px;
}
.tip-buttons .tip-button-back:hover {
    color: $secondary-font-color;
}
.tip-buttons .tip-button-back .back-arrow {
    margin-top: 2px;
}