/* purgecss start ignore */
@import './_variables.scss';


// Hide scroll bar for all browsers
@mixin hide-scrollbar {
    -ms-overflow-style: none; /* for IE and Edge */
    scrollbar-width: none; /* for Firefox */
    ::-webkit-scrollbar {
        display: none; /* for Chrome, Safari, and Opera */
    }
}

// Flexbox
@mixin flex-center {
    display: flex;
    justify-content: center;
    align-items: center;
}
  @mixin flex-Vcenter {
    display: flex;
    align-items: center;
  }
  @mixin flex-Hcenter {
    display: flex;
    justify-content: center;
  }
@mixin flex-start {
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
}
@mixin flex-end {
    display: flex;
    justify-content: flex-end;
    align-items: flex-end;
}
@mixin flex-space-between {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

@mixin flex-column {
    display: flex;
    flex-direction: column;
}

// Ellipsis
@mixin single-line-ellipsis {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}
  
@mixin multi-line-ellipsis($lines: 2) {
    display: -webkit-box;
    -webkit-line-clamp: $lines;
    -webkit-box-orient: vertical;
    overflow: hidden;
}

// Responsive
@mixin respond-to($breakpoint) {
    @if $breakpoint == small {
      @media (max-width: 670px) { @content; }
    } @else if $breakpoint == medium {
      @media (max-width: 900px) { @content; }
    } @else if $breakpoint == large {
      @media (max-width: 1089px) { @content; }
    } @else if $breakpoint == x-large {
      @media (max-width: 1235px) { @content; }
    }
  }
  

/* purgecss end ignore */