@import '../variables.scss';

.help-center-page {
    padding-bottom: 60px;
    
    .container {
        max-width: 1200px;
        margin: 0 auto;
        padding: 0 20px;
    }

    .hero-section {
        background-color: transparent;
        border-radius: 10px;
        color: $primary-color;
        padding: 80px 0;
        text-align: center;
        
        h1 {
            font-size: 2.8rem;
            margin-bottom: 20px;
            font-weight: 700;
        }
        
        .subtitle {
            font-size: 1.2rem;
            max-width: 700px;
            margin: 0 auto 40px;
            line-height: 1.6;
        }

        .search-container {
            max-width: 600px;
            margin: 0 auto;

            .search-input {
                position: relative;
                
                .search-icon {
                    position: absolute;
                    left: 20px;
                    top: 50%;
                    transform: translateY(-50%);
                    color: #888;
                }
                
                input {
                    width: 100%;
                    padding: 16px 20px 16px 50px;
                    font-size: 1rem;
                    border: none;
                    border-radius: 50px;
                    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
                    
                    &:focus {
                        outline: none;
                        box-shadow: 0 4px 20px rgba(0, 0, 0, 0.15);
                    }
                }
            }
        }
    }

    section {
        padding: 60px 0;

        h2 {
            font-size: 2rem;
            margin-bottom: 40px;
            text-align: center;
            color: $primary-color;
            font-weight: 600;
        }
    }

    .faq-section {
        background-color: #f9f9f9;
        
        .faqs {
            max-width: 800px;
            margin: 0 auto;
        }
        
        .faq-item {
            background-color: white;
            border-radius: 8px;
            margin-bottom: 16px;
            box-shadow: 0 2px 5px rgba(0, 0, 0, 0.05);
            overflow: hidden;
            
            .faq-question {
                display: flex;
                align-items: center;
                justify-content: space-between;
                padding: 20px;
                cursor: pointer;
                transition: background-color 0.3s;
                
                &:hover {
                    background-color: #f5f5f5;
                }
                
                h3 {
                    font-size: 1.1rem;
                    margin: 0;
                    font-weight: 500;
                }
                
                .toggle-icon {
                    color: $primary-color;
                }
            }
            
            .faq-answer {
                padding: 0 20px 20px;
                
                p {
                    margin: 0;
                    line-height: 1.6;
                    color: #555;
                }
            }
        }
        
        .no-results {
            text-align: center;
            padding: 30px;
            background-color: white;
            border-radius: 8px;
            
            p {
                color: #888;
            }
        }
    }

    .guides-section {
        .guides-grid {
            display: grid;
            grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));
            gap: 30px;
            
            .guide-card {
                background-color: white;
                border-radius: 8px;
                padding: 30px;
                box-shadow: 0 4px 10px rgba(0, 0, 0, 0.05);
                transition: transform 0.3s, box-shadow 0.3s;
                
                &:hover {
                    transform: translateY(-5px);
                    box-shadow: 0 8px 15px rgba(0, 0, 0, 0.1);
                }
                
                h3 {
                    font-size: 1.2rem;
                    margin-bottom: 15px;
                    color: $primary-color;
                    font-weight: 600;
                }
                
                p {
                    color: $secondary-font-color;
                    margin-bottom: 20px;
                    line-height: 1.5;
                }
                
                .guide-link {
                    display: inline-block;
                    color: $primary-color;
                    font-weight: 500;
                    text-decoration: none;
                    position: relative;
                    
                    &:after {
                        content: "→";
                        margin-left: 5px;
                        transition: transform 0.3s;
                    }
                    
                    &:hover:after {
                        transform: translateX(3px);
                    }
                }
            }
        }
    }

    .support-section {
        background-color: #f9f9f9;
        text-align: center;
        
        p {
            max-width: 700px;
            margin: 0 auto 40px;
            font-size: 1.1rem;
            color: #555;
        }
        
        .support-options {
            display: grid;
            grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
            gap: 30px;
            max-width: 800px;
            margin: 0 auto;
            
            .support-card {
                background-color: white;
                border-radius: 8px;
                padding: 30px;
                box-shadow: 0 4px 10px rgba(0, 0, 0, 0.05);
                
                h3 {
                    font-size: 1.2rem;
                    margin-bottom: 15px;
                    color: $primary-color;
                }
                
                p {
                    margin-bottom: 20px;
                    font-size: 1rem;
                }
                
                .support-button {
                    display: inline-block;
                    background-color: $primary-color;
                    color: white;
                    padding: 12px 25px;
                    border-radius: 50px;
                    text-decoration: none;
                    font-weight: 500;
                    transition: background-color 0.3s;
                    
                    &:hover {
                        background-color: darken($primary-color, 10%);
                    }
                }
            }
        }
    }
}

@media (max-width: 768px) {
    .help-center-page {
        .hero-section {
            padding: 60px 0;
            
            h1 {
                font-size: 2.2rem;
            }
        }
        
        section {
            padding: 40px 0;
            
            h2 {
                font-size: 1.8rem;
                margin-bottom: 30px;
            }
        }
        
        .guides-section .guides-grid,
        .support-section .support-options {
            grid-template-columns: 1fr;
        }
    }
} 