// Modals
.modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    width: 100vw;
    height: 100vh;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: $z-index-modal;
    backdrop-filter: blur(2px);
    animation: fadeIn 0.2s ease-out;
}
  
.linko-modal {
  background-color: white;
  border-radius: 10px;
  width: 620px;
  padding: 24px;
  @include respond-to(small) {
    width: 100%;
    margin: 0 15px;
  }
  
  .modal-header {
    width: 100%;
    display: flex;
    justify-content: space-between !important;
    align-items: center;
    margin-bottom: 30px;
    padding: 0;
    height: 30px;
  
    h2 {
      margin: 0;
      font-size: $large-header-text-size;
      font-weight: $font-weight-semibold;
      color: $primary-font-color;
    }
  
    .close-button {
      background: none;
      border: none;
      font-size: $x-large-header-text-size;
      cursor: pointer;
      color: $tertiary-font-color;
      &:hover {
        color: $primary-color;
      }
    }
  }
  
  .modal-body {

    min-height: fit-content;
    width: 100%;
    .input-group {
      margin-bottom: 20px;
  
      label {
        display: block;
        font-size: $tertiary-text-size;
        font-weight: 500;
        color: $primary-font-color;
        margin-bottom: 5px;
      }
  
      input {
        width: 100%;
        height: 50px;
        padding: 10px;
        background-color: $white;
        border: 1px solid $border-dark;
        border-radius: 5px;
        font-size: 18px;
        color: $secondary-font-color;
        &:focus {
          outline: none;
        }
      }
    }
  }
  
  .modal-footer {
    display: flex;
    justify-content: flex-end;
    gap: 10px;
    padding: 0;
  
    button {
      padding: 10px 20px;
      border: none;
      border-radius: 5px;
      font-size: 16px;
      cursor: pointer;
      transition: background-color 0.3s;
  
      &.cancel-button {
        background-color: transparent;
        color: $secondary-font-color;
        margin-right: 10px;
  
        &:hover {
          background-color: #f0f0f0;
        }
      }
  
      &.save-button {
        background-color: #4CAF50;
        color: white;
  
        &:hover {
          background-color: #45a049;
        }
      }
    }
  }

  .email {
    width: 100%;
    height: 50px;
    padding: 10px;
    background-color: $grey-hover;
    border: 1px solid $border-dark;
    border-radius: 5px;
    font-size: 18px;
    color: $secondary-font-color;
  }
}