/* purgecss start ignore */

@import 'variables';
@import 'mixins';

// ===== HOVER EFFECTS =====

// Hover effect for all elements
.hover-to-primary-color {
  cursor: pointer;
  transition: color $transition-fast;
  &:hover {
    color: $primary-color;
  }
}

.hover-add-underline {
  cursor: pointer;
  &:hover {
    text-decoration: underline;
  }
}

.hover-scale {
  transition: transform $transition-fast;
  &:hover {
    transform: scale(1.02);
  }
}

.hover-lift {
  transition: transform $transition-fast, box-shadow $transition-fast;
  &:hover {
    transform: translateY(-2px);
    box-shadow: $card-box-shadow;
  }
}

// ===== RESPONSIVE HELPERS =====

// Quick responsive utility classes
.hide-large {
  @include respond-to(large) {
    display: none !important;
  }
}

.hide-medium {
  @include respond-to(medium) {
    display: none !important;
  }
}

.hide-small {
  @include respond-to(small) {
    display: none !important;
  }
}

.show-large {
  display: none !important;
  @include respond-to(large) {
    display: initial !important;
  }
}

.show-medium {
  display: none !important;
  @include respond-to(medium) {
    display: initial !important;
  }
}

.show-small {
  display: none !important;
  @include respond-to(small) {
    display: initial !important;
  }
}

// ===== SCROLL HANDLING =====

// Hide scroll bar for all browsers
.hide-scrollbar {
  -ms-overflow-style: none; /* for IE and Edge */
  scrollbar-width: none; /* for Firefox */
  &::-webkit-scrollbar {
    display: none; /* for Chrome, Safari, and Opera */
  }
}

.smooth-scroll {
  scroll-behavior: smooth;
}

// ===== DIVIDERS =====

.rx-divider-vertical {
  border-bottom: 1px solid $border-light;
  width: 100%;
  margin: $spacing-sm 0;
}

.rx-divider-horizontal {
  border-right: 1px solid $border-normal;
  height: 100%;
  margin: 0 $spacing-sm;
}

// ===== SPACING UTILITIES =====

// Modern spacing utilities using the new spacing variables
@each $size, $value in (
  'xs': $spacing-xs,
  'sm': $spacing-sm,
  'md': $spacing-md,
  'lg': $spacing-lg,
  'xl': $spacing-xl,
  'xxl': $spacing-xxl
) {
  // Margin
  .m-#{$size} { margin: $value !important; }
  .mt-#{$size} { margin-top: $value !important; }
  .mr-#{$size} { margin-right: $value !important; }
  .mb-#{$size} { margin-bottom: $value !important; }
  .ml-#{$size} { margin-left: $value !important; }
  .mx-#{$size} { 
    margin-left: $value !important;
    margin-right: $value !important;
  }
  .my-#{$size} { 
    margin-top: $value !important;
    margin-bottom: $value !important;
  }

  // Padding
  .p-#{$size} { padding: $value !important; }
  .pt-#{$size} { padding-top: $value !important; }
  .pr-#{$size} { padding-right: $value !important; }
  .pb-#{$size} { padding-bottom: $value !important; }
  .pl-#{$size} { padding-left: $value !important; }
  .px-#{$size} { 
    padding-left: $value !important;
    padding-right: $value !important;
  }
  .py-#{$size} { 
    padding-top: $value !important;
    padding-bottom: $value !important;
  }
}

// Auto margins
.mx-auto {
  margin-left: auto !important;
  margin-right: auto !important;
}

.ml-auto { margin-left: auto !important; }
.mr-auto { margin-right: auto !important; }
.mt-auto { margin-top: auto !important; }
.mb-auto { margin-bottom: auto !important; }

// ===== TEXT ALIGNMENT =====

.text-left { text-align: left !important; }
.text-center { text-align: center !important; }
.text-right { text-align: right !important; }
.text-justify { text-align: justify !important; }

// ===== DISPLAY UTILITIES =====

.d-none { display: none !important; }
.d-inline { display: inline !important; }
.d-inline-block { display: inline-block !important; }
.d-block { display: block !important; }
.d-flex { display: flex !important; }
.d-inline-flex { display: inline-flex !important; }
.d-grid { display: grid !important; }

// ===== FLEXBOX UTILITIES =====

.flex-row { flex-direction: row !important; }
.flex-column { flex-direction: column !important; }
.flex-row-reverse { flex-direction: row-reverse !important; }
.flex-column-reverse { flex-direction: column-reverse !important; }

.flex-wrap { flex-wrap: wrap !important; }
.flex-nowrap { flex-wrap: nowrap !important; }

.justify-content-start { justify-content: flex-start !important; }
.justify-content-end { justify-content: flex-end !important; }
.justify-content-center { justify-content: center !important; }
.justify-content-between { justify-content: space-between !important; }
.justify-content-around { justify-content: space-around !important; }
.justify-content-evenly { justify-content: space-evenly !important; }

.align-items-start { align-items: flex-start !important; }
.align-items-end { align-items: flex-end !important; }
.align-items-center { align-items: center !important; }
.align-items-baseline { align-items: baseline !important; }
.align-items-stretch { align-items: stretch !important; }

.flex-grow-0 { flex-grow: 0 !important; }
.flex-grow-1 { flex-grow: 1 !important; }
.flex-shrink-0 { flex-shrink: 0 !important; }
.flex-shrink-1 { flex-shrink: 1 !important; }

// ===== POSITION UTILITIES =====

.position-relative { position: relative !important; }
.position-absolute { position: absolute !important; }
.position-fixed { position: fixed !important; }
.position-sticky { position: sticky !important; }

// ===== MISCELLANEOUS =====

.w-100 { width: 100% !important; }
.h-100 { height: 100% !important; }

.rounded { border-radius: $button-border-radius !important; }
.rounded-circle { border-radius: 50% !important; }
.rounded-pill { border-radius: 9999px !important; }

.overflow-hidden { overflow: hidden !important; }
.overflow-auto { overflow: auto !important; }
.overflow-scroll { overflow: scroll !important; }

.shadow-sm { box-shadow: 0 1px 2px rgba(0, 0, 0, 0.05) !important; }
.shadow { box-shadow: $card-box-shadow !important; }
.shadow-lg { box-shadow: 0 10px 25px rgba(0, 0, 0, 0.1) !important; }
.shadow-none { box-shadow: none !important; }

// ===== LEGACY MARGIN UTILITIES =====
// Keeping for backward compatibility

// Margin Top Legacy
.mt-5 {
  margin-top: $margin-5 !important;
}
.mt-10 {
  margin-top: $margin-10 !important;
}
.mt-15 {
  margin-top: $margin-15 !important;
}
.mt-20 {
  margin-top: $margin-20 !important;
}
// Margin Right Legacy
.mr-5 {
  margin-right: $margin-5 !important;
}
.mr-10 {
  margin-right: $margin-10 !important;
}
.mr-15 {
  margin-right: $margin-15 !important;
}
.mr-20 {
  margin-right: $margin-20 !important;
}
// Margin Bottom Legacy
.mb-5 {
  margin-bottom: $margin-5 !important;
}
.mb-10 {
  margin-bottom: $margin-10 !important;
}
.mb-15 {
  margin-bottom: $margin-15 !important;
}
.mb-20 {
  margin-bottom: $margin-20 !important;
}
// Margin Left Legacy
.ml-5 {
  margin-left: $margin-5 !important;
}
.ml-10 {
  margin-left: $margin-10 !important;
}
.ml-15 {
  margin-left: $margin-15 !important;
}
.ml-20 {
  margin-left: $margin-20 !important;
}

// ===== FORM ELEMENT UTILITIES =====

// Remove all borders, outlines, and focus styles
.no-border {
  border: none !important;
  outline: none !important;
  box-shadow: none !important;

  &:focus, &:active, &:hover {
    border: none !important;
    outline: none !important;
    box-shadow: none !important;
    -webkit-box-shadow: none !important;
    -moz-box-shadow: none !important;
  }
}

// Remove only focus outlines
.no-focus-outline {
  &:focus, &:active {
    outline: none !important;
    box-shadow: none !important;
    -webkit-box-shadow: none !important;
    -moz-box-shadow: none !important;
  }
}

// Text area with no resize
.no-resize {
  resize: none !important;
}

// 添加字体工具类 - Notion启发的字体类
.font-sans {
  font-family: $font-family-sans !important;
}

.font-serif {
  font-family: $font-family-serif !important;
}

.font-mono {
  font-family: $font-family-mono !important;
}

/* purgecss end ignore */