@import '../../variables';
@import '../../mixins';

.note-card-wrapper {
    position: relative;
    width: 620px;
    height: auto;
    @include respond-to(small) {
        width: 100%;
        padding: 0px;
      }
}

/* Note card and inside */
.note-card {
    @include flex-column;
    justify-content: space-between;
    width: 100%;
    height: fit-content;
    min-height: 150px;
    position: relative;
    border-radius: $card-border-radius;
    background: $white;
    border: 2px solid $border-light;
    -ms-overflow-style: none;
    scrollbar-width: none;
}
    .note-card-header {
        @include flex-space-between;
        align-items: flex-start;
        height: fit-content;
        width: 100%;
        height: 16px;
        margin: 15px 0px 8px 0px;
        padding: 0px 15px 0px 20px;

        .note-last-edited {
            @include flex-start;
            align-items: center;
            color: $tertiary-font-color;
            font-size: 12px;
            font-weight: 500;
            // font-style: italic;
            line-height: 16px;

            .note-formatted-time{
                color: $tertiary-font-color;
                font-size: 12px;
                font-weight: 500;
                // font-style: italic;
                line-height: 16px;
            }

            .note-lock-icon{
                @include flex-center;
                color: $tertiary-font-color;
                margin-left: 5px;
                margin-bottom: 2px;

            }
            .note-likes-indicator{
                @include flex-center;
                color: $tertiary-font-color;
                justify-content: flex-start;
                margin-left: 6px;
                height: 100%;
                column-gap: 2px;
                font-size: 13px;
                padding-bottom: 2px;
                cursor: pointer;
                
                svg{
                    height: 18px;
                    margin-bottom: 1px;
                }
            }
        }

        .MoreTabWLink{
            margin-bottom: 2px;
        }
        
    }
    .note-card-content {
        width: 100%;
        padding: 0px 15px 20px 20px;
    }
   

    .note-content {
        @include flex-column;
        min-width: 97%;
        height: fit-content;
        justify-content: space-between;
        color: $primary-font-color;
    }
    .note-title {
        max-width: 100%;
        height: fit-content;
        color: $primary-font-color !important;
        font-size: 18px;
        font-weight: 600;
        line-height: 1.3;
        margin-bottom: 8px;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: wrap;
        -ms-overflow-style: none; /* for IE and Edge */
        scrollbar-width: none; /* for Firefox */
        letter-spacing: -0.01em;

        &::-webkit-scrollbar {
            display: none; /* for Chrome, Safari, and Opera */
        }
    }
        .editing-note-title {
            width: 100%;
            height: 24px;
            color: $primary-font-color;
            font-size: 18px;
            font-weight: 600;
            border: transparent;
            border-radius: $button-border-radius;
            background-color: transparent;
            margin-bottom: 8px;
            resize: none;
            margin-right: 20px;
            padding: 0px 2px 0px 2px;
            -ms-overflow-style: none; /* for IE and Edge */
            scrollbar-width: none; /* for Firefox */
            line-height: 1.3;
            letter-spacing: -0.01em;
            
            &::-webkit-scrollbar {
                display: none; /* for Chrome, Safari, and Opera */
            }
        }
    .note-content-text {
        min-height: 50px;
        width: 100%;
        color: $primary-font-color;
        font-size: 16px;
        font-weight: 400;
        line-height: 1.6;
        margin-bottom: 5px;
        position: relative;
        letter-spacing: 0.003em;
        text-rendering: optimizeLegibility;
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;
        word-break: normal;
        overflow-wrap: break-word;

        p {
            margin: 8px 0;
            font-size: 16px;
            line-height: 1.6;
            letter-spacing: 0.003em;
        }
        
        h1, h2, h3 {
            margin: 16px 0 8px 0;
            font-size: 17px;
            font-weight: 700;
            line-height: 1.3;
            letter-spacing: -0.008em;
            color: $primary-font-color;
        }
        
        // Add specific styling for code blocks
        pre {
            background-color: $border-light;
            border-radius: 4px;
            padding: 15px;
            margin: 8px 0;
            overflow-x: auto;
            width: 100%;
            
            // Reset code styling when inside pre
            code {
                background-color: transparent;
                padding: 0;
                border-radius: 0;
                white-space: pre-wrap;
                display: block;
                overflow-x: auto;
                color: $primary-font-color;
                font-family: monospace;
            }
        }

        ul, ol {
            padding-left: 24px !important;
            margin: 6px 0 !important;
        }

        * > ul, * > ol {
            margin: 6px 0 !important;
        }

        li {
            margin: 6px 0;
            display: list-item !important;
        }
        
        // Add specific styling for markdown lists
        .md-list {
            padding-left: 24px !important;
        }
        
        .md-list-item {
            margin: 6px 0 !important;
            line-height: 1.6 !important;
            display: list-item !important;
        }
        
        // Properly style list indicators
        ul > li, 
        ul.md-list > li.md-list-item {
            list-style-type: disc !important;
        }
        
        ul > li > ul > li,
        ul.md-list > li.md-list-item > ul.md-list > li.md-list-item {
            list-style-type: circle !important;
        }
        
        ul > li > ul > li > ul > li,
        ul.md-list > li.md-list-item > ul.md-list > li.md-list-item > ul.md-list > li.md-list-item {
            list-style-type: square !important;
        }
        
        // Fix ordered list styling - make all use consistent numbering
        ol > li,
        ol.md-list > li.md-list-item {
            list-style-type: decimal !important;
        }
        
        ol > li > ol > li,
        ol.md-list > li.md-list-item > ol.md-list > li.md-list-item {
            list-style-type: lower-alpha !important;
        }
        
        ol > li > ol > li > ol > li,
        ol.md-list > li.md-list-item > ol.md-list > li.md-list-item > ol.md-list > li.md-list-item {
            list-style-type: lower-roman !important;
        }
        
        // Improve blockquote styling with better spacing
        blockquote.note-blockquote {
            border-left: 3px solid $border-light;
            color: $secondary-font-color;
            font-style: italic;
            margin: 20px 0 !important;
            padding: 5px 10px !important;
            position: relative;
            
            // Ensure blockquotes have correct spacing
            + blockquote.note-blockquote {
                margin-top: 10px !important;
            }
            
            // Headers in blockquotes
            h1, h2, h3 {
                margin: 8px 0;
                color: $secondary-font-color;
                font-style: italic;
                background-color: transparent;
            }
            
            // Improved list styling in blockquotes
            ul, ol, .md-list {
                padding-left: 24px !important;
                margin: 6px 0 !important;
                background-color: transparent;
                
                li, .md-list-item {
                    color: $secondary-font-color;
                    font-style: italic;
                    margin: 6px 0 !important;
                    background-color: transparent;
                }
            }
        }
    }
        @media (max-width: 1404px) {
            .note-content-text {
                max-height: fit-content;
            }
        }
    .note-content-text::after {
        content: "";
        position: absolute;
        bottom: 0;
        left: 0;
        right: 0;
        height: 50px; 
        background: linear-gradient(to bottom, rgba(255, 255, 255, 0), rgba(255, 255, 255, 1));
        display: none;
        z-index: 93;
    }
    .note-content-text.overflowed::after {
        display: block;
    }

    .note-expand-button {
        @include flex-center;
        background-color: transparent;
        border: none;
        margin-right: auto;
        color: $tertiary-font-color;
        margin-bottom: 15px;
    }
    .note-expand-button:hover {
        color: $primary-color;
    }
    .note-content-text::-webkit-scrollbar {
        width: 8px;
    }
    .note-content-text:hover::-webkit-scrollbar {
        width: 8px;
    }
    .note-content-text::-webkit-scrollbar-thumb {
        border-radius: 8px;
    }
    .note-content-text:hover::-webkit-scrollbar-thumb {
        background: #c4c4c4;
        border-radius: 8px;
    }
    .note-content-text::-webkit-scrollbar-thumb:hover {
        background: #a0a0a0;
    }
        .editing-note-content-text {
            max-width: 96%;
            text-align: left;
            line-height: 1.6;
            overflow: auto;
            border: transparent;
            border-radius: $input-border-radius;
            background-color: transparent;
            font-size: 16px;
            font-weight: 400;
            resize: none;
            padding: 2px 2px 0px 2px;
            margin-right: 20px;
            margin-bottom: 3px;
            color: $primary-font-color;
            letter-spacing: 0.003em;
            text-rendering: optimizeLegibility;
            -webkit-font-smoothing: antialiased;
            -moz-osx-font-smoothing: grayscale;
        }

// Slim Note Card
.slim-note-card {
    display: flex;
    border-radius: $card-border-radius;
    padding: 12px;
    margin-bottom: 0px;
    cursor: pointer;
    border: 2px solid $border-normal;

    &:hover {
        background-color: $white;
    }
}

.icon-container {
    flex-shrink: 0;
    margin-right: 12px;
}

.note-icon {
    font-size: 20px;
    color: $tertiary-font-color;
}

.content-container {
    flex-grow: 1;
    overflow: hidden;
}

.resource-title {
    margin: 0 0 4px 0;
    font-size: 14px;
    font-weight: 600;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    color: $primary-font-color;
}

.resource-content {
    font-size: 14px;
    line-height: 1.4;
    color: $secondary-font-color;
    max-height: 2.8em; /* 2 lines * 1.4 line-height */
    overflow: hidden;
}

.resource-content p {
    margin: 0;
}

// Chinese text optimizations
.note-content-text:lang(zh), 
.note-content-text:lang(zh-CN) {
    line-height: 1.8;
    letter-spacing: 0.02em;
}

// Chinese list optimizations
.note-content-text:lang(zh) ul, 
.note-content-text:lang(zh) ol,
.note-content-text:lang(zh-CN) ul,
.note-content-text:lang(zh-CN) ol {
    padding-left: 2em;
}

.subject-container {
  width: 100%;
  margin-top: 16px;
}

.subject-loading {
  display: flex;
  align-items: center;
  gap: 8px;
  padding: 6px 0;
  color: $tertiary-font-color;
  font-size: 13px;
  
  .subject-loading-indicator {
    width: 14px;
    height: 14px;
    border: 2px solid $border-light;
    border-top: 2px solid $primary-color;
    border-radius: 50%;
    animation: spin 1.5s linear infinite;
  }
}

@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}

// Ensure the list items in preview match the editor
.note-content-text,
.DraftEditor-root {
  ul, ol {
    padding-left: 24px !important;
  }
  
  li {
    display: list-item !important;
    background-color: transparent;
  }
  
  // Fix for blockquotes within the editor
  blockquote {
    ul, ol {
      padding-left: 24px !important;
    }
    background-color: transparent;
  }
}