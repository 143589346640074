
/* Note card subjects */
.note-card-subjects .subjects {
    display: flex;
    height: fit-content;
    flex-wrap: wrap;
    column-gap: 8px;
    row-gap: 6px;
    margin-bottom: 0px !important;
}

.minus-icon {
    color: #9f9d9d;
    font-size: 18px;
    cursor: pointer;
    margin: 3px 0px;
}
.add-note-subject{
    margin-top: 1px;
    height: 24px;
    width: 24px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    border: 1px solid $border-normal;
    &:hover {
        background-color: rgba($secondary-color, 0.3);
        .add-subject-icon {
            color: $primary-color;
        }
    }
    .add-subject-icon {
        height: 24px;
        color: $tertiary-font-color;
        font-size: 14px;
        cursor: pointer;
    }
}

.subjects .searching {
    width: 120px;
    height: 24px;
    margin-top: 1px;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    padding: 4px 4px;
    background: none;
    border: 1px solid $secondary-color;
    border-radius: 13px;
}
.add-note-subject-textarea {
    width: 120px;
    height: 18px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: flex-end; /* Add this line to align the text vertically */
    border: none;
    font-size: 13px;
    color: $primary-color;
    resize: none;
    margin-left: 2px;
    outline: none; 
    white-space: nowrap;
    overflow-x: auto;
}
    .add-note-subject-textarea::-webkit-scrollbar {
        display: none; /* Hide the scrollbar */
    }

.subject-search-result {
    width: 100%;
    max-height: 150px;
    position: relative;
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
    row-gap: 3px;
    overflow: auto;
    background: #ffffff;
    padding: 5px;
    z-index: 1;
    border-left: 2px solid $secondary-color;
}

.subject-search-result .loader-container {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    min-height: 50px;
}

.suggesting-cus-label {
    height: 26px;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    padding: 0px 4px;
    margin-bottom: 5px;
    column-gap: 5px;
    font-size: 13px;
    color: #9f9d9d;
}

.divider {
    color: #63636386;
    width: 100%;
    margin: 0px;
    margin-top: 6px;
}