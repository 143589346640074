@import '../../../Stylesheets/_variables.scss';

#resource-card {
    width: 620px;
    height: auto;
    display: flex;
    flex-direction: column;
    align-items: stretch; 
    justify-content: space-between;
    border-radius: $card-border-radius;
    background: #ffffff;
    padding: 15px 15px 12px 20px;
    line-height: 150%;
    border: 2px solid #f2f3f4;
    overflow: visible; 
    
    @include respond-to (small) {
        width: 100%;
    }

    .add-to-library-button {
        @include flex-center;
        justify-content: flex-start;
        font-size: 14px;
        font-weight: 500;
        height: 25px;
        padding: 0px 5px;
        width: fit-content;
    }

    .MoreTabWLink {
        cursor: pointer;
        width: 20px;
        height: 20px;
        align-self: flex-start; 
        flex-shrink: 0;
        position: relative;
    }

    .card-action-dropdown {
        top: 100%;
        right: -20px;
    }
}
    #resource-card .card-header {
        display: flex;
        align-items: center;
        justify-content: space-between;
        width: 100%;
        column-gap: 0px;
        color: $tertiary-font-color;
        box-shadow: none;

        .card-header-left {
            display: flex;
            align-items: center;
            column-gap: 10px;
            .resource-type-icon {
                margin-right: 3px;
                margin-top: 1px;
            }    
            .header-group {
                display: flex;
                align-items: center;
                column-gap: 5px;
            }
        }

        .type-text {
            font-size: 14px;
            font-weight: 500;
            color: $tertiary-font-color;
            text-transform: uppercase;
        }
    }
    


    #resource-card .resource-type-icon {
        flex-shrink: 0;
        overflow: hidden;
        font-size: 24px; 
        align-self: flex-start; 
        margin-top: 3px;
        margin-right: 10px;
    }
    .resource-info {
        flex: 1;
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
    }
        .text-image-info {
            flex: 1;
            display: flex;
            flex-direction: row;
            justify-content: space-between;
        }
            .resource-title-author{
                height: fit-content;
                display: flex;
                flex-direction: column;
                justify-content: flex-start;
                overflow: hidden; 
                margin-bottom: 10px;
                color: $primary-font-color;
                row-gap: 15px;
                margin-top: 8px;
                line-height: 1.2;

                .resource-title {
                    max-height: 54px;
                    display: -webkit-box;
                    -webkit-line-clamp: 2;
                    line-clamp: 2;
                    -webkit-box-orient: vertical;
                    overflow: hidden;
                    text-overflow: ellipsis;
                    word-wrap: break-word;
                    font-size: 18px;
                    font-weight: 700;
                    color: $primary-font-color;
                    cursor: pointer;
                    white-space: wrap;
                    word-break: break-word;
                    margin: 0;

                    &:hover {
                        color: $primary-color;
                    }

                    &.no-hover {
                        color: $primary-font-color;
                        cursor: default;
                        
                        &:hover {
                            color: $primary-font-color;
                        }
                    }
                }


                .author {
                    height: fit-content;
                    flex-shrink: 0;
                    vertical-align: middle;
                    text-align: left;
                    color: $secondary-font-color;
                    font-size: 14px;
                    line-height: 16px;
                    font-weight: 400;
                    display: inline-flex;
                    column-gap: 5px;
                    align-items: center;
                    white-space: wrap;
                    flex-wrap: wrap;
                    max-width: 100%; 
                }
            }

                    .resource-card-upper-right .subjects {
                        width: 100%;
                        height: auto;
                        display: flex;
                        justify-self: flex-end;
                        flex-shrink: 0;
                        flex-wrap: wrap;
                        column-gap: 8px;
                        row-gap: 6px;
                    } 

            #resource-card .note-card-subjects {
                margin-top: auto;
            }

        #resource-card .resource-image {
            width: 17%;
            display: flex;
            align-items: flex-start;
            justify-content: center;
            flex-shrink: 0; 
            overflow: hidden; 
            margin-top: 5px;
            position: relative;
            img {
                object-fit: contain;
                padding: 5px;
                border-radius: 8px;
            }
        }



// Resource Thumbnail Placeholder

.resource-thumbnail-placeholder {
    display: flex;
    align-items: center;
    justify-content: center;
    background: linear-gradient(to bottom, #ffffff 50%, #f5f5f5 50%);
    border: 1px solid #ddd;
    border-radius: 4px;
    position: relative;
}

.resource-thumbnail-placeholder-background {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
    position: relative;
}

.resource-thumbnail-placeholder .resource-type-icon {
    position: absolute;
    top: 50%;
    left: 33%;
    transform: translate(-50%, -50%);
    color: #ccc;
}


.slim-resource-card {
    height: fit-content;
    min-height: 45px;
    width: 100%;
    @include flex-start;
    align-items: center;
    justify-content: space-between;
    column-gap: 8px;
    border-radius: $card-border-radius;
    color: $secondary-font-color;
    font-weight: 500;
    transition: all 0.2s ease;
    margin-bottom: 12px;
    border: 2px solid $border-normal;
    cursor: pointer;
    background-color: transparent;

    &:hover {
        // background-color: $white;
        box-shadow: $card-box-shadow;
    }

    .add-resource-icon {
        cursor: pointer;
        color: $tertiary-font-color;
        transition: color 0.2s ease;
        z-index: 1;
        padding-right: 8px;

        &:hover {
            color: $primary-color;
        }
    }

    .unlink-resource-icon {
        cursor: pointer;
        color: $tertiary-font-color;
        transition: color 0.2s ease;
        z-index: 1;
        padding-right: 10px;
        display: flex;
        align-items: center;
        column-gap: 5px;

        &:hover {
            color: $primary-color;
        }
    }


    .slim-resource-card-content {
        display: flex;
        align-items: flex-start;
        flex-grow: 1;
        gap: 15px;
    }

    .slim-resource-thumbnail {
        width: 60px;
        height: 85px;
        flex-shrink: 0;
        border-radius: 8px 0 0 8px;
        overflow: hidden;
        background-color: #f5f5f5;

        img {
            width: 100%;
            height: 100%;
            object-fit: cover;
        }
    }

    .resource-thumbnail-placeholder {
        border: none;
        border-radius: 8px 0 0 8px;
    }

    .slim-resource-details {
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        flex-grow: 1;
        height: 85px;
        gap: 5px;
        padding: 8px 5px 5px 0px;
    }

    .slim-resource-header {
        display: flex;
        flex-direction: column;
        gap: 8px;
        margin-bottom: 3px;
    }

    .slim-resource-type {
        font-size: 12px;
        color: #999999;
        margin: 0;
        font-weight: 500;
    }

    .slim-resource-card-title {
        font-size: 16px;
        font-weight: 600;
        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-box-orient: vertical;
        -webkit-line-clamp: 1;
        line-height: 1.3;
        margin: 0;
        color: $primary-font-color !important;
        white-space: wrap;

        .inline-type-icon {
            display: inline-block;
            vertical-align: text-bottom;
            margin-right: 4px;
            line-height: 0;
            margin-bottom: 2px;
        }
    }

    .slim-resource-footer {
        display: flex;
        flex-direction: column;
    }

    .slim-resource-info-row {
        display: flex;
        align-items: center;
        justify-content: space-between;
        width: 100%;
    }

    .slim-resource-card-author {
        font-size: 14px; 
        color: $secondary-font-color;
        font-weight: 400;
        margin: 0;
        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-box-orient: vertical;
        -webkit-line-clamp: 1;
        line-height: 1.3;
    }

    .slim-resource-stats {
        display: flex;
        align-items: center;
        gap: 8px;
    }

    .notes-count {
        font-size: 13px;
        color: $tertiary-font-color;
    }

    .progress-indicator {
        font-weight: 600;
        padding: 2px 8px;
        border-radius: 12px;
        font-size: 12px;
        min-width: 46px;
        text-align: center;
        background-color: $tertiary-color;
        color: $primary-color;
    }
}


// Learned Users Button

.learned-users-button {
    display: flex;
    align-items: center;
    gap: 5px;
    background: none;
    border: none;
    cursor: pointer;
    height: 25px;
    padding: 0px;
    svg {
        stroke-width: 0.75;
    }
}







