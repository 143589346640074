/* purgecss start ignore */
@import './_variables';
@import './_mixins';

// Basic Card
.linko-card {
    @include flex-center;
    width: 620px;
    height: fit-content;
    background-color: $white;
    border-radius: $card-border-radius;
    border: $border-width-thin solid $border-light;
    padding: $spacing-md;
    margin-bottom: $spacing-xs;

    @include respond-to(small) {
        width: 100%;
    }
}

// Card variations
.linko-card {
    &--elevated {
        box-shadow: 0 6px 16px rgba(0, 0, 0, 0.1);
    }
    
    &--interactive {
        cursor: pointer;
        
        &:hover {
            transform: translateY(-2px);
        }
        
        &:active {
            transform: translateY(0);
        }
    }
    
    &--flat {
        box-shadow: none;
        border: $border-width-thin solid $border-normal;
        
        &:hover {
            box-shadow: $card-box-shadow;
        }
    }
    
    &--transparent {
        background-color: transparent;
        box-shadow: none;
        border: none;
    }
}

.card-content {
    padding: $spacing-sm 0;
    width: 100%;
}

.card-title {
    font-size: $secondary-title-size;
    font-weight: $font-weight-semibold;
    color: $primary-font-color;
    margin-bottom: $spacing-xs;
}

.card-subtitle {
    font-size: $primary-text-size;
    font-weight: $font-weight-medium;
    color: $secondary-font-color;
    margin-bottom: $spacing-xs;
}

// Ensure all cards have white background
.note-card,
#resource-card
 {
    background-color: $white;
    &:hover {
        box-shadow: $card-box-shadow;
    }
}

/* purgecss end ignore */