@import './V0_variables.scss';

body {
  font-family: 'Inter', -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
  color: $text-color;
  background-color: #fff;
  line-height: 1.5;
}

.V0_LandingPage {
  display: flex;
  min-height: 100vh;
  min-width: 100vw;
  flex-direction: column;
  margin-top: -70px;
  background-color: #fff;

  .button {
    display: inline-flex;
    align-items: center;
    justify-content: center;
    padding: 0.5rem 1.25rem;
    font-size: 0.875rem;
    font-weight: 500;
    border-radius: 0.375rem;
    transition: all 0.2s ease;
    cursor: pointer;
    height: 2.5rem;
    border: 1px solid transparent;
    
    .icon {
      width: 1rem;
      height: 1rem;
      margin-left: 0.5rem;
    }
  
    &--primary {
      background-color: $secondary-color;
      color: white;
      box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);
  
      &:hover {
        background-color: darken($secondary-color, 5%);
      }
    }
  
    &--outline {
      background-color: transparent;
      border-color: $border-color;
      color: $text-secondary;
  
      &:hover {
        background-color: rgba(0, 0, 0, 0.05);
      }
    }
  
    &--light {
      background-color: white;
      color: $primary-color;
  
      &:hover {
        background-color: rgba(255, 255, 255, 0.9);
      }
  
      &.button--outline {
        background-color: transparent;
        border-color: white;
        color: white;
  
        &:hover {
          background-color: rgba(255, 255, 255, 0.1);
        }
      }
    }
  
    &--full-width {
      width: 100%;
    }
  }
  
  

  .header {
    position: sticky;
    top: 0;
    z-index: 40;
    width: 100%;
    background-color: rgba(255, 255, 255, 0.8);
    backdrop-filter: blur(8px);
    // border-bottom: 1px solid $border-color;
    padding: 0px;
  
    &__content {
      display: flex;
      align-items: center;
      justify-content: space-between;
      height: 5rem;
    }
  
    &__logo {
      display: flex;
      align-items: center;
      gap: 0.5rem;
      font-size: 1.5rem;
      font-weight: 700;
      color: $secondary-color;
    }
  
    &__logo-icon {
      width: 2rem;
      height: 2rem;
    }
  
    &__nav {
      display: none;
      
      @media (min-width: 768px) {
        display: flex;
        align-items: center;
        gap: 1.5rem;
      }
  
      &--open {
        display: flex;
        flex-direction: column;
        position: absolute;
        top: 100%;
        left: 0;
        right: 0;
        background-color: white;
        padding: 1.5rem;
        box-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.1);
        border-bottom: 1px solid $border-color;
        gap: 1.5rem;
        z-index: 30;
  
        .header__link {
          padding: 0.5rem 0;
        }
  
        .header__buttons {
          flex-direction: column;
          width: 100%;
          gap: 0.75rem;
          margin-top: 0.5rem;
  
          button {
            width: 100%;
          }
        }
      }
    }
  
    &__link {
      font-size: 0.875rem;
      font-weight: 500;
      color: $text-secondary;
      transition: color 0.2s ease;
  
      &:hover {
        color: $primary-color;
      }
    }
  
    &__buttons {
      display: flex;
      gap: 0.75rem;
    }
  
    &__menu-button {
      display: flex;
      align-items: center;
      justify-content: center;
      background: none;
      border: none;
      cursor: pointer;
      color: $text-color;
      
      @media (min-width: 768px) {
        display: none;
      }
    }
  }
  
  


main {
  flex: 1;
}

.container {
  width: 100%;
  max-width: 1200px;
  margin: 0 auto;
  padding: 0 1.5rem;
}

a {
  text-decoration: none;
  color: inherit;
  transition: color 0.2s ease;
}

img {
  max-width: 100%;
  height: auto;
}

.section-header {
  text-align: center;
  max-width: 800px;
  margin: 0 auto 4rem;

  h2 {
    font-size: 1.75rem;
    font-weight: 700;
    color: $text-color;
    margin-bottom: 1rem;
    line-height: 1.2;

    @media (min-width: 768px) {
      font-size: 2.25rem;
    }
  }

  p {
    font-size: 1.125rem;
    color: $text-secondary;
    max-width: 800px;
    margin: 0 auto;

    @media (min-width: 768px) {
      font-size: 1.25rem;
    }
  }
}

.badge {
  display: inline-flex;
  align-items: center;
  padding: 0.25rem 0.75rem;
  border-radius: 9999px;
  font-size: 0.875rem;
  font-weight: 500;
  background-color: rgba($primary-color, 0.05);
  color: $primary-color;
  border: 1px solid rgba($primary-color, 0.2);
  margin-bottom: 1rem;
  width: fit-content;
}

.grid-pattern {
  position: absolute;
  inset: 0;
  background-image: url("data:image/svg+xml,%3Csvg width='60' height='60' viewBox='0 0 60 60' xmlns='http://www.w3.org/2000/svg'%3E%3Cg fill='none' fill-rule='evenodd'%3E%3Cg fill='%23000000' fill-opacity='0.03'%3E%3Cpath d='M36 34v-4h-2v4h-4v2h4v4h2v-4h4v-2h-4zm0-30V0h-2v4h-4v2h4v4h2V6h4V4h-4zM6 34v-4H4v4H0v2h4v4h2v-4h4v-2H6zM6 4V0H4v4H0v2h4v4h2V6h4V4H6z'/%3E%3C/g%3E%3C/g%3E%3C/svg%3E");
  pointer-events: none;
  opacity: 0.03;
  z-index: 0;
}

// Hero Section
.hero {
  position: relative;
  padding: 6rem 0 4rem;
  overflow: hidden;

  .badge {
    margin-bottom: 0px;
  }

  @media (min-width: 768px) {
    padding: 8rem 0;
  }

  &__content {
    display: grid;
    gap: 3rem;
    position: relative;
    z-index: 1;

    @media (min-width: 992px) {
      grid-template-columns: 1fr 600px;
      align-items: center;
      gap: 4rem;
    }
  }

  &__text {
    display: flex;
    flex-direction: column;
    gap: 3rem;
  }

  h1 {
    font-size: 1.5rem;
    font-weight: 700;
    line-height: 1.2;
    color: $text-secondary;
    margin-bottom: -2.5rem;

    .animated-text {
      display: inline-block;
      animation: fadeInOut 4s ease-in-out infinite;
      font-weight: bold;
      color: $primary-color;
    }

    // @media (min-width: 768px) {
    //   font-size: 3.5rem;
    // }
  }

  p {
    font-size: 2.25rem;
    font-weight: 700;
    line-height: 1.4;
    color: $text-color;

    // @media (min-width: 768px) {
    //   font-size: 1.25rem;
    // }
  }

  &__buttons {
    display: flex;
    flex-direction: column;
    gap: 1rem;
    margin-top: 0.5rem;

    @media (min-width: 576px) {
      flex-direction: row;
    }
  }

  &__users {
    display: flex;
    align-items: center;
    gap: 1rem;
    font-size: 0.875rem;
    color: $text-secondary;

    strong {
      color: $primary-color;
      font-weight: 500;
    }
  }

  &__avatars {
    display: flex;
    margin-right: 0.5rem;
  }

  &__avatar {
    width: 2rem;
    height: 2rem;
    border-radius: 50%;
    border: 2px solid #fff;
    overflow: hidden;
    margin-left: -0.5rem;
    
    &:first-child {
      margin-left: 0;
    }

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }

  &__image-container {
    position: relative;
  }

  &__image-wrapper {
    position: relative;
    width: 100%;
    height: 100%;

    .main-image {
      width: 100%;
      height: auto;
      border-radius: 12px;
      box-shadow: 0 8px 12px -1px rgba(0, 0, 0, 0.15);
      margin-left: -30px;
      position: relative;
      z-index: 2;

      @media (max-width: 768px) {
        margin-left: 0;
      }
    }

    .sample-note-image {
      position: absolute;
      bottom: -70px;
      right: -70px;
      width: 500px;
      height: auto;
      border-radius: 12px;
      box-shadow: 0 8px 12px -1px rgba(0, 0, 0, 0.15);
      z-index: 3;

      @media (max-width: 768px) {
        width: 300px;
        bottom: -40px;
        right: -20px;
      }

      @media (max-width: 480px) {
        width: 200px;
        bottom: -30px;
        right: -10px;
      }
    }
  }

  &__image-glow {
    position: absolute;
    border-radius: 1rem;
    filter: blur(1rem);
    opacity: 0.7;
    
    &.main-glow {
      top: 0;
      left: -30px;
      width: 100%;
      height: 100%;
      background: linear-gradient(to right, rgba($secondary-color, 0.2), rgba($primary-color, 0.2));
      z-index: 1;

      @media (max-width: 768px) {
        left: 0;
      }
    }
    
    &.sample-glow {
      width: 500px;
      height: 370px;
      bottom: -70px;
      right: -70px;
      background: linear-gradient(135deg, rgba($primary-color, 0.2), rgba($secondary-color, 0.2));
      z-index: 1;

      @media (max-width: 768px) {
        width: 300px;
        height: 220px;
        bottom: -40px;
        right: -20px;
      }

      @media (max-width: 480px) {
        width: 200px;
        height: 150px;
        bottom: -30px;
        right: -10px;
      }
    }
  }

  &__image-blob {
    position: absolute;
    bottom: -1.5rem;
    right: -1.5rem;
    height: 6rem;
    width: 6rem;
    background-color: rgba($secondary-color, 0.1);
    border-radius: 50%;
    filter: blur(1rem);
  }
}

// Short Quotes Section
.short-quotes {
  padding: 3rem 0;
  position: relative;
  margin-top: 5rem;

  .container {
    width: calc(100% + 2rem);
    margin: 0 auto;
    padding: 0;
  }

  &__content {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    gap: 1.5rem 2.5rem;
    
    @media (min-width: 768px) {
      gap: 1.5rem 4rem;
    }
  }
}

.short-quote {
  font-size: 1rem;
  font-weight: 500;
  font-style: italic;
  line-height: 1.4;
  
  @media (min-width: 768px) {
    font-size: 1.25rem;
  }
}


// Features Section
.features {
  padding: 5rem 0;
  position: relative;
  overflow: hidden;

  &__grid {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 3rem;
    margin-top: 3rem;
  }

  .feature {
    position: relative;
    padding: 1rem;
    border-radius: 1rem;
    transition: background-color 0.3s ease;
    width: 800px;

    @media (max-width: 768px) {
      width: 100%;
    }

    &:hover {
      background-color: rgba(0, 0, 0, 0.02);
    }

    &__header {
      display: flex;
      align-items: center;
      gap: 1rem;
      margin-bottom: 1.5rem;
    }

    &__icon {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 3rem;
      height: 3rem;
      border-radius: 50%;
      background-color: rgba($primary-color, 0.1);
      color: $primary-color;
    }

    h3 {
      font-size: 1.25rem;
      font-weight: 600;
      color: $text-color;
    }

    p {
      color: $text-secondary;
      margin-bottom: 2rem;
      line-height: 1.6;
    }

    &__image {
      position: relative;
      border-radius: 0.75rem;
      overflow: hidden;
      border: 1px solid rgba(0, 0, 0, 0.1);
      box-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.1), 0 2px 4px -1px rgba(0, 0, 0, 0.06);

      &.small {
        width: 600px;
        justify-self: center;
        margin: 3rem 0;
      }
    }

    &__image-overlay {
      position: absolute;
      inset: 0;
      background: linear-gradient(to top right, rgba($primary-color, 0.05), transparent);
      opacity: 0.6;
      pointer-events: none;
      z-index: 1;
    }

    img {
      display: block;
      width: 100%;
    }

    &__resource-switcher {
      display: flex;
      justify-content: center;
      gap: 1.5rem;
      margin: 1.5rem 0;
      
      .resource-icon {
        display: flex;
        flex-direction: column;
        align-items: center;
        padding: 0.75rem;
        border-radius: 0.5rem;
        cursor: pointer;
        transition: all 0.2s ease;
        color: $text-secondary;
        
        svg {
          font-size: 1.5rem;
          margin-bottom: 0.5rem;
        }
        
        span {
          font-size: 0.875rem;
          font-weight: 500;
        }
        
        &:hover {
          background-color: rgba($primary-color, 0.05);
          color: $primary-color;
        }
        
        &.active {
          background-color: rgba($primary-color, 0.1);
          color: $primary-color;
          position: relative;
          
          // &:after {
          //   content: '';
          //   position: absolute;
          //   bottom: -0.25rem;
          //   left: 50%;
          //   transform: translateX(-50%);
          //   width: 1.5rem;
          //   height: 2px;
          //   background-color: $primary-color;
          //   border-radius: 1px;
          // }
        }
      }
    }
  }
}

// Sub-Features Section
.sub-feature {
  display: flex;
  flex-direction: column;
  padding: 1.5rem;
  border-radius: 1rem;
  background-color: white;
  box-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.05), 0 2px 4px -1px rgba(0, 0, 0, 0.03);
  border: 1px solid rgba(0, 0, 0, 0.05);
  transition: transform 0.3s ease, box-shadow 0.3s ease;
  height: 100%;
  
  &:hover {
    transform: translateY(-5px);
    box-shadow: 0 10px 15px -3px rgba(0, 0, 0, 0.1), 0 4px 6px -2px rgba(0, 0, 0, 0.05);
  }

  &__icon {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 3rem;
    height: 3rem;
    border-radius: 0.75rem;
    background: linear-gradient(135deg, rgba($primary-color, 0.1), rgba($secondary-color, 0.1));
    color: $primary-color;
    margin-bottom: 1.25rem;
    
    .icon {
      width: 1.5rem;
      height: 1.5rem;
    }
  }

  &__content {
    flex: 1;
    
    h3 {
      font-size: 1.125rem;
      font-weight: 600;
      color: $text-color;
      margin-bottom: 0.75rem;
    }
    
    p {
      font-size: 0.9375rem;
      color: $text-secondary;
      line-height: 1.6;
    }
  }
}

// Testimonials Section
.testimonials {
  position: relative;
  padding: 6rem 0;
  // background: linear-gradient(to bottom, #fff, $accent-color);

  @media (min-width: 768px) {
    padding: 8rem 0;
  }

  &__grid {
    display: grid;
    gap: 2rem;

    @media (min-width: 768px) {
      grid-template-columns: repeat(3, 1fr);
    }
  }
}

// What's More Section
.whats-more {
  padding: 6rem 0;

  @media (min-width: 768px) {
    padding: 8rem 0;
  }

  &__grid {
    display: grid;
    gap: 2rem;

    @media (min-width: 400px) {
      grid-template-columns: repeat(1, 1fr);
    }
    @media (min-width: 768px) {
      grid-template-columns: repeat(2, 1fr);
    }
    @media (min-width: 900px) {
      grid-template-columns: repeat(4, 1fr);
    }

  }
}

.testimonial {
  position: relative;
  background: white;
  border-radius: 1rem;
  padding: 2rem;
  box-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.1), 0 2px 4px -1px rgba(0, 0, 0, 0.06);
  border: 1px solid rgba(0, 0, 0, 0.1);

  &__glow {
    position: absolute;
    top: 0;
    right: 0;
    width: 5rem;
    height: 5rem;
    background-color: rgba($secondary-color, 0.1);
    border-radius: 50%;
    filter: blur(1rem);
    margin-top: -0.5rem;
    margin-right: -0.5rem;
  }

  &__stars {
    display: flex;
    color: $secondary-color;
    margin-bottom: 1.5rem;

    .star {
      width: 1.25rem;
      height: 1.25rem;

      &.filled {
        fill: currentColor;
      }
    }
  }

  blockquote {
    position: relative;
    margin-bottom: 2rem;

    .quote-mark {
      position: absolute;
      top: -1.5rem;
      left: -0.5rem;
      font-size: 5rem;
      color: rgba($primary-color, 0.1);
      line-height: 1;
    }

    p {
      position: relative;
      z-index: 1;
      color: $text-secondary;
    }
  }

  &__author {
    display: flex;
    align-items: center;
    gap: 1rem;
  }

  &__avatar {
    width: 3rem;
    height: 3rem;
    border-radius: 50%;
    overflow: hidden;
    border: 2px solid white;
    box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }

  &__name {
    font-weight: 500;
    color: $text-color;
  }

  &__title {
    font-size: 0.875rem;
    color: $text-secondary;
  }
}

// Pricing Section
.pricing {
  padding: 6rem 0;

  @media (min-width: 768px) {
    padding: 8rem 0;
  }

  &__card-container {
    margin: 0 auto;
    display: flex;
    flex-direction: row;
    gap: 2rem;
    justify-content: center;

    @media (max-width: 768px) {
      flex-direction: column;
    }
  } 

  &__card {
    position: relative;
  }

  &__card-glow {
    position: absolute;
    inset: -0.5rem;
    background: linear-gradient(to right, rgba($secondary-color, 0.3), rgba($primary-color, 0.3));
    border-radius: 1rem;
    filter: blur(0.5rem);
    opacity: 0.3;
  }

  &__card-content {
    position: relative;
    background: white;
    border-radius: 1rem;
    padding: 2rem;
    box-shadow: 0 10px 15px -3px rgba(0, 0, 0, 0.1), 0 4px 6px -2px rgba(0, 0, 0, 0.05);
    border: 1px solid rgba(0, 0, 0, 0.1);
    height: 600px;

  }

  &__card-header {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    margin-bottom: 1.5rem;

    h3 {
      font-size: 1.5rem;
      font-weight: 700;
      color: $text-color;
    }

    p {
      color: $text-secondary;
      margin-top: 0.25rem;
    }
  }

  &__badge {
    background-color: rgba($primary-color, 0.1);
    color: $primary-color;
    font-size: 0.75rem;
    font-weight: 600;
    padding: 0.25rem 0.75rem;
    border-radius: 9999px;
  }

  &__price {
    display: flex;
    align-items: baseline;
    margin-bottom: 2rem;
  }

  &__amount {
    font-size: 3rem;
    font-weight: 700;
    color: $text-color;
  }

  &__period {
    color: $text-secondary;
    margin-left: 0.25rem;
  }

  &__features {
    margin-bottom: 2rem;
  }

  &__feature {
    display: flex;
    align-items: flex-start;
    margin-bottom: 1rem;

    .pricing__check {
      color: $secondary-color;
      margin-right: 0.75rem;
      flex-shrink: 0;
      margin-top: 0.125rem;
    }
  }

  &__note {
    text-align: center;
    font-size: 0.875rem;
    color: $text-secondary;
    margin-top: 1rem;
  }
}

// FAQ Section
.faq {
  position: relative;
  padding: 6rem 0;
  // background: linear-gradient(to bottom, $accent-color, #fff);

  @media (min-width: 768px) {
    padding: 8rem 0;
  }

  &__container {
    max-width: 48rem;
    margin: 0 auto;
  }
}

// CTA Section
.cta {
  position: relative;
  padding: 5rem 0;
  background-color: white;
  color: $text-color;
  overflow: hidden;

  &__gradient {
    position: absolute;
    inset: 0;
    background: radial-gradient(circle at bottom, rgba($secondary-color, 0.3), transparent 40%);
  }

  &__content {
    position: relative;
    z-index: 1;
    // max-width: 36rem;
    margin: 0 auto;
    text-align: center;

    h2 {
      font-size: 1.75rem;
      font-weight: 700;
      margin-bottom: 1.5rem;

      @media (min-width: 768px) {
        font-size: 2.25rem;
      }
    }

    p {
      font-size: 1.125rem;
      opacity: 0.9;
      margin-bottom: 2.5rem;
      max-width: 36rem;
      margin-left: auto;
      margin-right: auto;
      color: $text-secondary;

      @media (min-width: 768px) {
        font-size: 1.25rem;
      }
    }
  }

  &__buttons {
    display: flex;
    flex-direction: column;
    gap: 1rem;
    justify-content: center;

    @media (min-width: 576px) {
      flex-direction: row;
    }
  }

  &__fade {
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    height: 4rem;
    background: linear-gradient(to top, white, transparent);
  }
}

.accordion {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 1rem;

  &__item {
    border: 1px solid $border-color;
    border-radius: 0.75rem;
    overflow: hidden;
    box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);
    background-color: white;

    &--open {
      .accordion__icon {
        transform: rotate(180deg);
      }

      .accordion__content {
        max-height: 1000px;
      }
    }
  }

  &__trigger {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 1rem 1.5rem;
    background: none;
    border: none;
    text-align: left;
    font-size: 1rem;
    font-weight: 500;
    color: $text-color;
    cursor: pointer;
    transition: color 0.2s ease;

    &:hover {
      color: $primary-color;
    }
  }

  &__icon {
    width: 1.25rem;
    height: 1.25rem;
    transition: transform 0.3s ease;
    flex-shrink: 0;
  }

  &__content {
    max-height: 0;
    overflow: hidden;
    transition: max-height 0.3s ease;
  }

  &__content-inner {
    padding: 0 1.5rem 1.5rem;
    color: $text-secondary;
    line-height: 1.6;
  }
}

.footer {
  padding: 3rem 0;
  background-color: white;

  &__grid {
    display: grid;
    gap: 2rem;
    
    @media (min-width: 768px) {
      grid-template-columns: repeat(2, 1fr);
    }
    
    @media (min-width: 992px) {
      grid-template-columns: repeat(4, 1fr);
    }
  }

  &__brand {
    p {
      color: $text-secondary;
      max-width: 20rem;
      margin-top: 1rem;
      text-align: left;
    }
  }

  &__logo {
    display: flex;
    align-items: center;
    gap: 0.5rem;
    font-size: 1.25rem;
    font-weight: 700;
    color: $secondary-color;
    margin-bottom: 1rem;
  }

  &__logo-icon {
    width: 1.5rem;
    height: 1.5rem;
  }

  &__links {
    h3 {
      font-size: 1rem;
      font-weight: 600;
      color: $text-color;
      margin-bottom: 1rem;
    }

    ul {
      list-style: none;
      padding: 0;
      margin: 0;
    }

    li {
      margin-bottom: 0.75rem;
    }

    a {
      color: $text-secondary;
      transition: color 0.2s ease;

      &:hover {
        color: $primary-color;
      }
    }
  }

  &__bottom {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    margin-top: 3rem;
    padding-top: 2rem;
    border-top: 1px solid $border-color;
    
    @media (min-width: 768px) {
      flex-direction: row;
    }

    p {
      font-size: 0.875rem;
      color: $text-secondary;
    }
  }

  &__social {
    display: flex;
    gap: 1.5rem;
    margin-top: 1rem;
    
    @media (min-width: 768px) {
      margin-top: 0;
    }

    a {
      font-size: 0.875rem;
      color: $text-secondary;
      transition: color 0.2s ease;

      &:hover {
        color: $primary-color;
      }
    }
  }
}

}