/* purgecss start ignore */
@import './_variables';

button {
    cursor: pointer;
    border: none;
    background-color: transparent;
    white-space: nowrap;
    
    &:active {
        outline: none;
    }
}

.button-span {
    @include flex-center;
    @include flex-Hcenter;
    column-gap: $spacing-xs;
}

.linko-button {
    @include flex-center;
    height: 36px;
    padding: $spacing-xs $spacing-md;
    border-radius: $button-border-radius;
    border: none;
    font-size: $primary-text-size;
    font-weight: $font-weight-medium;
    cursor: pointer;
    column-gap: $spacing-xs;
    transition: all $transition-fast;
    box-shadow: none;
    position: relative;
    overflow: hidden;

    &:focus {
        outline: none;
    }

    &:active {
        outline: none;
    }

    &--primary {
        background-color: $secondary-color;
        color: $white;
        transition: all $transition-normal;
        
        &:hover {
            background-color: darken($secondary-color, 7.5%);
            color: $white;
        }
        
        &:active {
            transform: translateY(1px);
        }

        &--disabled {
            background-color: $grey-background;
            &:hover {
                background-color: $grey-background;
                color: $white;
                cursor: not-allowed;
            }
        }
    }

    &--secondary {
        background-color: transparent;
        color: $primary-color;
        border: $border-width-thin solid $primary-color;
        transition: all $transition-normal;
        letter-spacing: 0.2px;
        
        &:hover {
            background-color: $primary-color;
            color: $white;
            box-shadow: $button-box-shadow;
        }
    }

    &--secondary-outline {
        background-color: $white;
        color: $tertiary-font-color;
        border: $border-width-thin solid #ccc;
        cursor: not-allowed;
        pointer-events: none;
        opacity: 0.8;
    }

    &--tertiary {
        background-color: $white;
        color: $tertiary-font-color;
        transition: all $transition-normal;
        
        &:hover {
            background-color: $grey-hover;
            color: $primary-color;
        }
        
        &:active {
            transform: translateY(1px);
        }

        &--disabled {
            &:hover {
                background-color: $white;
                color: $tertiary-font-color;
                cursor: default;
            }
        }
    }

    &--cancel {
        background-color: transparent;
        color: $tertiary-font-color;
        transition: all $transition-normal;
        
        &:hover {
            background-color: $grey-hover;
            color: $danger-color;
        }
        
        &:active {
            transform: translateY(1px);
        }
    }

    &--danger {
        background-color: $danger-color;
        color: $white;
        transition: all $transition-normal;
        
        &:hover {
            background-color: darken($danger-color, 7.5%);
            color: $white;
        }
    }
}

// .linko-button .button-text {
//     @include respond-to(medium) {
//         display: none;
//     }
// }


.back-button-wrapper {
    position: absolute;
    top: 5px;
    left: -60px;
    background-color: transparent;

    @include respond-to(x-large) {
        left: -40px;
    }
    @include respond-to(large) {
        width: 100%;
        margin-right: auto;
        margin-bottom: $spacing-md;
        left: 10px;
        position: inherit;
        width: fit-content;
        @include flex-start;
    }
    @include respond-to(large) {
        margin-left: -20px;
    }
    @include respond-to(small) {
        margin-left: 0px;
        left: 10px;
    }
}
.back-button-wrapper .back-button {
    padding: $spacing-sm;
    background-color: transparent;
    transition: transform $transition-fast;
    
    &:hover {
        transform: translateX(-2px);
        color: $primary-color;
    }
}

.show-button {
    font-size: $tertiary-text-size;
    color: $tertiary-font-color;
    margin: 0;
    width: fit-content;
    height: 26px;
    white-space: nowrap;
    @include flex-Hcenter;
    align-items: center;
    transition: color $transition-fast;
    
    &:hover {
        color: $primary-color;
    }
}

@keyframes ripple {
    0% {
        transform: scale(0, 0);
        opacity: 0.5;
    }
    20% {
        transform: scale(25, 25);
        opacity: 0.5;
    }
    100% {
        opacity: 0;
        transform: scale(40, 40);
    }
}
/* purgecss end ignore */