@import '../../variables';
@import '../../mixins';

// Wrapper that contains both the trigger and the tooltip
.tooltip-wrapper {
  position: relative;
  display: inline-block;
}

// The element that triggers the tooltip on hover
.tooltip-trigger {
  display: inline-block;
}

// The container for the tooltip
.tooltip-container {
  position: absolute;
  z-index: $z-index-tooltip;
  max-width: 300px;
  min-width: 80px;
  opacity: 0;
  visibility: hidden;
  pointer-events: none;
  transition: 
    opacity $transition-fast,
    visibility $transition-fast,
    transform $transition-fast;
  transform: scale(0.95);
  
  &.tooltip-visible {
    opacity: 1;
    visibility: visible;
    transform: scale(1);
  }
}

// The actual content of the tooltip
.tooltip-content {
  font-size: $tertiary-text-size;
  padding: $spacing-xs $spacing-sm;
  border-radius: $tooltip-border-radius;
  white-space: nowrap;
  text-align: center;
  line-height: $line-height-tight;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.15);
}

// Position Variants
.tooltip-top {
  bottom: 100%;
  left: 50%;
  transform: translateX(-50%) scale(0.95);
  margin-bottom: 8px;
  
  &.tooltip-visible {
    transform: translateX(-50%) scale(1);
  }
  
  &.tooltip-arrow::after {
    content: '';
    position: absolute;
    top: 100%;
    left: 50%;
    transform: translateX(-50%);
    border-width: 5px;
    border-style: solid;
  }
}

.tooltip-bottom {
  top: 100%;
  left: 50%;
  transform: translateX(-50%) scale(0.95);
  margin-top: 8px;
  
  &.tooltip-visible {
    transform: translateX(-50%) scale(1);
  }
  
  &.tooltip-arrow::after {
    content: '';
    position: absolute;
    bottom: 100%;
    left: 50%;
    transform: translateX(-50%);
    border-width: 5px;
    border-style: solid;
  }
}

.tooltip-left {
  right: 100%;
  top: 50%;
  transform: translateY(-50%) scale(0.95);
  margin-right: 8px;
  
  &.tooltip-visible {
    transform: translateY(-50%) scale(1);
  }
  
  &.tooltip-arrow::after {
    content: '';
    position: absolute;
    left: 100%;
    top: 50%;
    transform: translateY(-50%);
    border-width: 5px;
    border-style: solid;
  }
}

.tooltip-right {
  left: 100%;
  top: 50%;
  transform: translateY(-50%) scale(0.95);
  margin-left: 8px;
  
  &.tooltip-visible {
    transform: translateY(-50%) scale(1);
  }
  
  &.tooltip-arrow::after {
    content: '';
    position: absolute;
    right: 100%;
    top: 50%;
    transform: translateY(-50%);
    border-width: 5px;
    border-style: solid;
  }
}

// Theme Variants
.tooltip-dark {
  .tooltip-content {
    background-color: $primary-font-color;
    color: white;
  }
  
  &.tooltip-arrow {
    &.tooltip-top::after { border-color: $primary-font-color transparent transparent transparent; }
    &.tooltip-bottom::after { border-color: transparent transparent $primary-font-color transparent; }
    &.tooltip-left::after { border-color: transparent transparent transparent $primary-font-color; }
    &.tooltip-right::after { border-color: transparent $primary-font-color transparent transparent; }
  }
}

.tooltip-light {
  .tooltip-content {
    background-color: white;
    color: $primary-font-color;
    border: 1px solid $border-light;
  }
  
  &.tooltip-arrow {
    &.tooltip-top::after { border-color: white transparent transparent transparent; }
    &.tooltip-bottom::after { border-color: transparent transparent white transparent; }
    &.tooltip-left::after { border-color: transparent transparent transparent white; }
    &.tooltip-right::after { border-color: transparent white transparent transparent; }
  }
}

.tooltip-primary {
  .tooltip-content {
    background-color: $primary-color;
    color: white;
  }
  
  &.tooltip-arrow {
    &.tooltip-top::after { border-color: $primary-color transparent transparent transparent; }
    &.tooltip-bottom::after { border-color: transparent transparent $primary-color transparent; }
    &.tooltip-left::after { border-color: transparent transparent transparent $primary-color; }
    &.tooltip-right::after { border-color: transparent $primary-color transparent transparent; }
  }
}

.tippy-box[data-theme~='light-border'] {
    background-color: #fff;
    color: #636363;
    border: 1px solid #fff;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    border-radius: $tooltip-border-radius;
    z-index: 99999999;
}
    .tippy-box[data-theme='light-border'][data-placement^='top'] > .tippy-arrow::before {
        border-top-color:  #fff;
    }
    .tippy-box[data-theme='light-border'][data-placement^='bottom'] > .tippy-arrow::before {
        border-bottom-color:  #fff;
        z-index: 99999999;
    }
    .tippy-box[data-theme='light-border'][data-placement^='left'] > .tippy-arrow::before {
        border-left-color:  #fff;
    }
    .tippy-box[data-theme='light-border'][data-placement^='right'] > .tippy-arrow::before {
        border-right-color: #fff;
    }


.tippy-box[data-theme~='heavy-shadow'] {
    background-color: #fff;
    color: #636363;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.15);
    border-radius: $tooltip-border-radius;
    z-index: 99999999;
} 
    .tippy-box[data-theme='heavy-shadow'][data-placement^='top'] > .tippy-arrow::before {
        border-top-color:  #fff;
    }
    .tippy-box[data-theme='heavy-shadow'][data-placement^='bottom'] > .tippy-arrow::before {
        border-bottom-color:  #fff;
        z-index: 99999999;
    }
    .tippy-box[data-theme='heavy-shadow'][data-placement^='left'] > .tippy-arrow::before {
        border-left-color:  #fff;
    }
    .tippy-box[data-theme='heavy-shadow'][data-placement^='right'] > .tippy-arrow::before {
        border-right-color: #fff;
    }



// Heatmap tooltip 
#heatmapTooltip {
    z-index: 9999 !important;
    color: black !important;
    background-color: white !important;
    border: 1px solid #ccc !important;
    padding: 5px !important;
}