.recent-PKN {
    height: fit-content;
    width: 100%;
    padding: 0px;
    margin-top: 12px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    position: relative;
    @include respond-to(large){
        padding: 0;
    }
    @include respond-to(small){
        width: 100%;
        margin-top: 0px;
        padding: 20px;
    }
}


.PKN-tooltip {
    position: absolute;
    top: 10px;
    right: 10px;
    color: #bdbdbd;
    z-index: 96;
}


.knowledge-graph-container {
    @include flex-center;
    flex-direction: column;
    width: 100%;
    height: fit-content;
    min-height: 320px;
    margin: 0px 0px 0 0px;
    padding: 10px;
    position: relative;
}

.knowledge-graph-container .loader-container {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #ffffff;
    border-radius: $card-border-radius;
    z-index: 2;
    position: absolute;
    left: 0;
}


#div1 {
    display: none;
    justify-content: center;
    align-items: center;
    /* height: 100%;
    min-height: 600px;
    width: 100%;
    min-width: 600px; */
}
  
.has-text-link.mt-3 {
    font-style: italic;
    font-size: 16px;
}

