#all-content {
    min-height: calc(100vh - 120px);
}

#mainContent {
    min-height: calc(100vh - 120px);
    display: flex;
    margin-top: 70px;
    justify-items: center;
    align-items: flex-start;
    justify-content: center;
    @include respond-to (medium) {
        margin-top: 60px;
    }
    @include respond-to (small) {
        width: 100vw;
        padding: 0px 0px 0px 0px;
    }
}


/* New rule for #minContent */
#minContent {
    margin-top: 0 !important;
}

#logo {
    display: flex;
    align-items: flex-end;
    justify-content: flex-start;
    height: 32px;
    cursor: pointer;
    column-gap: 0.25rem;
    white-space: nowrap;
    flex-shrink: 0;
    flex-wrap: nowrap;

    img {
        height: 32px;
        width: 32px;
    }

    h1 {
        font-size: 1.5rem;
        font-weight: 700;
        color: $secondary-color !important;
        height: 32px;
    }
}

.cards-container {
    @include flex-start;
    @include flex-column;
    width: 100%;
    flex-wrap: nowrap;
    row-gap: 20px;
    column-gap: 20px;
    // padding-right: 20px;
    @include respond-to(small) {
        padding: 10px 0px;
        width: 100%;
    }
}


/* Loader */

.loader-container {
    @include flex-center;
    width: 100%;
    height: 100%;
    min-height: 100px;
    &::before {
        background-color: $light-background;
    }
}

// .the-loader {
//     width: 50px !important;
//     height: 50px !important;
// }

// .the-loader span {
//     height: 50px !important;
//     width: 50px !important;
// }

.cards-container .the-loader span {
    height: 30px !important;
    width: 30px !important;
}


.tab-content-loader {
    @include flex-center;
    width: 600px;
    height: 100px;
    padding: 20px 0px;

    @include respond-to(small) {
        width: 100%;
    }
}
.tab-content-loader .the-loader {
    width: 30px !important;
    height: 30px !important;
}

.library-tabs .tab-content-loader {
    @include flex-center;
    width: 100%;
    height: 200px;
}


.page-loading {
    width: 100vw;
    height: 100vh;
}

.add-note-card .loader-container {
    width: 100%;
    height: 250px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.add-modal .loader-container {
    width: 100%;
    height: 250px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.full-search-results .loader-container {
    width: 100%;
    height: 150px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.full-search-results .loader-container .the-loader {
    width: 30px !important;
    height: 30px !important;
}

.full-search-results .loader-container .the-loader span {
    width: 30px !important;
    height: 30px !important;
}

.library-cards-container .tab-content-loader .loader-container {
    width: 100%;
    height: 150px;
    display: flex;
    align-items: center;
    justify-content: center;
}


.suggested-resources .loader-container {
    width: 100%;
    height: 50px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.suggested-resources .loader-container .the-loader {
    width: 30px !important;
    height: 30px !important;
}

.suggested-resources .loader-container .the-loader span {
    width: 30px !important;
    height: 30px !important;
}

/* Hidden on mobile */
@media screen and (max-width: 670px) {
    .hidden-on-mobile {
        display: none;
    }
}

/* Hidden on pad */
@media screen and (max-width: 1024px) {
    .hidden-on-pad {
        display: none;
    }
}

/* Hidden on desktop */
@media screen and (min-width: 1025px) {
    .hidden-on-desktop {
        display: none;
    }
}

/* Tooltiptext */
/* Tooltip container */

#tooltip {
    max-width: 800px;
    font-size: 15px;
    position: absolute;
    opacity: 0;
    background-color: white;
    padding: 8px;
    border: 1px solid black;
    border-radius: $tooltip-border-radius;
    pointer-events: none;
    white-space: pre-wrap;
    word-wrap: break-word;
    font-family: system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
}

.tooltip-container {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    height: fit-content;
    background-color: transparent;
    color: #636363;
}

/* Tooltip text */
.tooltip-container .tooltiptext {
    visibility: hidden;
    width: fit-content;
    background-color: #fff;
    color: #a7a7a7;
    text-align: center;
    padding: 5px 5px;
    border-radius: 6px;

    /* Position the tooltip text */
    position: absolute;
    z-index: 999;
    bottom: 0;
    /* Place the tooltip above the button */
    left: 100%;
    /* margin-left: -60px; Use half of the width to center the tooltip */

    /* Fade in tooltip */
    opacity: 0;
    transition: opacity 0.01s;
}

/* Show the tooltip text when you mouse over the tooltip container */
.tooltip-container:hover .tooltiptext {
    visibility: visible;
    opacity: 1;
}

.tooltip {
    background-color: black;
    color: white;
    padding: 5px;
    border-radius: 4px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.15);
}

.show-on-hover {
    display: none;
    &:hover {
        display: flex;
    }
}

.hover-to-green:hover {
    color: $primary-color !important;
    cursor: pointer;
}

.Toastify__toast-container {
    top: 60px !important;
    right: 32px !important;
}

*,
::after,
::before {
    box-sizing: inherit;
}

.required {
    color: #fb4242;
}


strong {
    font-weight: 600;
}

.hide-trigger-dot::before {
    display: none;
}

.react-joyride__tooltip .react-joyride__close {
    display: none !important;
}

.no-scroll {
    overflow: hidden;
}

// Add these color consistency styles to ensure elements properly blend with background

// Elements that should explicitly have white background
.white-bg-element {
  background-color: $white;
}

// Elements that should match the page background color
.page-bg-element {
  background-color: $light-background;
}

// Fix loader background
.loader-container {
  &::before {
    background-color: $light-background;
  }
}

// Fix subject-rel-title
.subject-rel-title {
  background-color: $light-background;
}

// Fix other potential inconsistencies
.content-tab-wrapper, 
.content-tab-panel,
.tab-content {
  background-color: transparent;
}

// Cards and content containers
.cards-wrapper,
.cards-container,
.content-container {
  background-color: transparent;
}

// Override any elements that should have the light background
#mainContent,
.page-content,
.section-wrapper {
  background-color: $light-background;
}
