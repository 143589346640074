/* purgecss start ignore */

// Brand Colors - Preserving the existing color scheme
$primary-color: #257953;
$secondary-color: #48c78e;
$tertiary-color: #effaf5;
$badge-color: #F27215;
$custom-color: #F27215;
$danger-color: #fb4242;
$success-color: #48c78e;
$warning-color: #FFD43B;
$info-color: #3273dc;

// Typography - Font Families
// Main font stack inspired by Notion
$font-family-sans: "Inter", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen, Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
$font-family-serif: Georgia, Cambria, 'Times New Roman', Times, serif;
$font-family-mono: 'SFMono-Regular', Consolas, 'Liberation Mono', Menlo, Courier, monospace;

// Font Colors
$primary-font-color: #1f2937;
$secondary-font-color: #4a5568;
$tertiary-font-color: #9ca3af;
$light-font-color: #ffffff;

// Background Colors
$grey-background: #dddddd;
$light-background: #f7f9f8;
$grey-hover: #f3f3f3;
$white: #ffffff;

// Typography - Font Sizes
$primary-text-size: 16px;
$secondary-text-size: 14px;
$tertiary-text-size: 12px;
$large-text-size: 18px;
$x-large-text-size: 20px;
$large-header-text-size: 24px;
$x-large-header-text-size: 28px;

// Typography - Heading Sizes
$primary-title-size: 24px;
$secondary-title-size: 20px;
$tertiary-title-size: 16px;
$hero-title-size: 32px;

// Typography - Font Weights
$font-weight-light: 300;
$font-weight-regular: 400;
$font-weight-medium: 500;
$font-weight-semibold: 600;
$font-weight-bold: 700;

$primary-text-weight: $font-weight-medium;
$secondary-text-weight: $font-weight-regular;
$username-text-weight: $font-weight-semibold;

// Line Heights
$line-height-tight: 1.2;
$line-height-normal: 1.5;
$line-height-loose: 1.8;

// Spacing System
$spacing-xs: 4px;
$spacing-sm: 8px;
$spacing-md: 16px;
$spacing-lg: 24px;
$spacing-xl: 32px;
$spacing-xxl: 48px;

// Border Variables
$card-border-radius: 10px;
$button-border-radius: 5px;
$input-border-radius: 5px;
$tooltip-border-radius: 5px;
$tag-border-radius: 20px;

// Border Width
$border-width-thin: 1px;
$border-width-normal: 2px;
$border-width-thick: 3px;

// Border Colors
$border-light: #f2f3f4;
$border-normal: #f0f0f0;
$border-dark: #c0c0c0;

// Box Shadow Variables
$card-box-shadow: 0 2px 10px rgba(0, 0, 0, 0.07);
$accent-box-shadow: 0 2px 10px rgba(72, 199, 142, 0.5);
$dropdown-box-shadow: 0 4px 12px rgba(0, 0, 0, 0.08);
$button-box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
$focus-box-shadow: 0 0 0 3px rgba($primary-color, 0.25);

// Transition
$transition-fast: 0.15s ease;
$transition-normal: 0.25s ease;
$transition-slow: 0.4s ease;

// Z-index
$z-index-dropdown: 1000;
$z-index-sticky: 1020;
$z-index-modal: 99999999999;
$z-index-tooltip: 1040;
$z-index-popover: 1040;
$z-index-toast: 1050;

// Margin Variables (legacy)
$margin-5: 5px;
$margin-10: 10px;
$margin-15: 15px;
$margin-20: 20px;

// Export SCSS variables as CSS custom properties
:root {
  // Brand Colors
  --primary-color: #{$primary-color};
  --secondary-color: #{$secondary-color};
  --tertiary-color: #{$tertiary-color};
  --badge-color: #{$badge-color};
  --custom-color: #{$custom-color};
  --error-color: #{$danger-color};
  --success-color: #{$success-color};
  --warning-color: #{$warning-color};
  --info-color: #{$info-color};

  // Font Colors
  --primary-font-color: #{$primary-font-color};
  --secondary-font-color: #{$secondary-font-color};
  --tertiary-font-color: #{$tertiary-font-color};
  --light-font-color: #{$light-font-color};

  // Background Colors
  --grey-background: #{$grey-background};
  --light-background: #{$light-background};
  --grey-hover: #{$grey-hover};
  --white: #{$white};

  // Border Colors
  --border-light: #{$border-light};
  --border-normal: #{$border-normal};
  --border-dark: #{$border-dark};

  // Box Shadows
  --card-box-shadow: #{$card-box-shadow};
  --accent-box-shadow: #{$accent-box-shadow};
}

/* purgecss end ignore */