.heatmap {
    padding: 0px 10px 10px 10px;
    margin-top: 0px;
    
    .month-labels {
        height: 20px;
        position: relative;
        margin-bottom: 3px;
        
        .month-label {
            position: absolute;
            font-size: 11px;
            color: $secondary-font-color;
        }
    }

    .heatmap-container {
        display: flex;
        gap: 5px;
		width: 100%;
    }

    .heatmap-week {
        display: flex;
        flex-direction: column;
        gap: 5px;
    }

    .heatmap-square {
        width: 17px;
        height: 17px;
        border-radius: 25%;
        cursor: pointer;

        &.color-empty {
            background-color: #eeeeee;
        }
        
        &.color-github-1 {
            background-color: #d6e685;
        }
        
        &.color-github-2 {
            background-color: #8cc665;
        }
        
        &.color-github-3 {
            background-color: $secondary-color;
        }
        
        &.color-github-4 {
            background-color: #216e39;
        }
    }
}
