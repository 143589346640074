@import '../../variables';

.resource-page-container {
    height: fit-content;
    width: 100%;
    display: flex;
    justify-content: center;
    column-gap: 5%;
    padding: 0px 5%;
    overflow-x: hidden;
    @include respond-to(x-large) {
        padding: 0px 20px;
        column-gap: 20px;
    }

    @include respond-to(small) {
        padding: 0px;
    }

    .podcast-player-container {
        width: 100%;
        border-radius: 8px;

        .subject-rel-title {
            color: $primary-color;
            font-size: 16px;
            font-family: system-ui, -apple-system, "Segoe UI", Roboto;
            margin: 10px 0px;
            padding: 10px 0px;
            background: $light-background;
        }

        .podcast-player-wrapper {
            width: 100%;
            margin-bottom: 10px;

            iframe {
                border-radius: 4px;
            }
        }

        .podcast-description {
            padding: 0;
            margin-bottom: 20px;
            color: $secondary-font-color;
            line-height: 1.6;

            h3 {
                font-size: 18px;
                font-weight: 600;
                color: $primary-font-color;
                margin: 25px 0 15px 0;
                &:first-child {
                    margin-top: 0;
                }
            }

            .podcast-sponsors {
                margin-bottom: 25px;
                
                a.external-link {
                    color: #2E7D32;
                    text-decoration: none;
                    word-break: break-all;
                    &:hover {
                        text-decoration: underline;
                    }
                }
            }

            .podcast-outline {
                margin-bottom: 25px;
                
                .outline-item {
                    margin-bottom: 8px;
                    display: flex;
                    align-items: flex-start;

                    .timestamp {
                        color: #2E7D32;
                        font-weight: 500;
                        min-width: 70px;
                        margin-right: 8px;
                    }
                }
            }

            .podcast-description-text {
                white-space: pre-line;
                margin-bottom: 10px;

                a.external-link {
                    color: #2E7D32;
                    text-decoration: none;
                    &:hover {
                        text-decoration: underline;
                    }
                }

                .note-expand-button {
                    margin: 10px 0 0 0;
                    padding: 0;
                }
            }
        }
    }
}

.resource-page-left {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    position: relative;
    width: 620px !important;
    @include respond-to(large) {
        align-items: flex-start;
    }
    @include respond-to(small) {
        width: 100%;
        padding: 0px;
    }
    .add-note-card-button {
        @include respond-to(small) {
            padding: 0px;
            margin-top: 10px;
            margin-bottom: 20px;
        }
    }
    .the-tabs {
        margin-top: 0px;
        padding-left: 5px;
        @include respond-to(large) {
            padding-left: 10px;
        }
        @include respond-to(small) {
            padding-left: 5px;
        }
    }
    .back-button-wrapper{
        @include respond-to(large) {
            display: inherit;
            margin-left: 0;
            margin-bottom: 10px;
        }
    }
}

.resource-description {
    @include respond-to(large) {
        width: 100%;
        padding: 0 20px;
    }
}

.resource-page-right-wrapper {
    @include flex-start;
    @include flex-column;   
    max-width: 670px;
    flex-grow: 1;
    overflow-x: hidden !important;
    height: fit-content;
    padding-bottom: 30px;
    @include hide-scrollbar;
}


.frame-preview {
    width: 100%;
    height: calc(100vh - 100px);
}

.resource-book-overview .other-books {
    width: 100%;
}