@import '../variables';
@import '../mixins';

// Fix for subject-rel-title
.subject-rel-title {
  background-color: $light-background;
  padding: $spacing-md;
  margin-bottom: $spacing-md;
}

.subject-tag {
    width: fit-content;
    height: 26px;
    font-size: 13px;
    display: flex;
    flex-wrap: nowrap;
    align-items: center;
    justify-content: center;
    padding: 4px 8px;
    border-radius: 13px;
    position: relative;
    cursor: pointer;
    white-space: nowrap;

    a{
        font-size: 13px;
        color: #535353;
    }

    &.studied {
        background-color: $tertiary-color;
        color: $primary-color;
        &:hover {
            background-color: rgba($secondary-color, 0.3);
        }
    
        a{
            font-size: 13px;
            color: $primary-color !important;
        }
    }

    &.not-studied {
        background-color: #f3f3f3;
        color: $secondary-font-color;
        &:hover {
            background-color: #e5e5e5;
        }
    }
}

.sibling-field {
    color: $tertiary-font-color;
    &:hover {
        background-color: $grey-hover;
    }
}

.cus-label {
    @include flex-center;
    height: 26px;
    width: fit-content;
    padding: 4px 8px;
    font-size: 13px;
    border-radius: 5px;
    cursor: pointer;
    position: relative;
    color: $custom-color;
    white-space: nowrap;
    background-color: rgba($custom-color, 0.05);

    a, p {
        font-size: 13px;
        color: $custom-color !important;
    }

    &:hover {
        background-color: rgba($custom-color, 0.15);
    }

    &.inactive {
        background-color: rgba($secondary-font-color, 0.05);
        color: $secondary-font-color;
        &:hover {
            background-color: rgba($secondary-font-color, 0.15);
        }
    }
}


/* Note Card Specifics */
.subject-tag .delete-subject-icon {
    font-size: 22px;
    margin-left: 1px;
    cursor: pointer;
    visibility: hidden;
    position: absolute;
    top: -10px;
    right: -10px;
    display: flex;
    align-items: flex-start;
    justify-content: flex-end;
    box-sizing: border-box;
    color: #bbbbbb;
}
.delete-subject-icon:hover {
    color: #ba2525;
}
.note-card-subjects .subject-tag:hover .delete-subject-icon{
    visibility: visible;
}
.note-card-subjects .cus-label:hover .delete-subject-icon{
visibility: visible;
}
.cus-label .delete-subject-icon:hover {
    color: #ba2525;
}