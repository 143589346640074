.add-note-card-button {
    width: 100%;
    // padding-right: 20px;
    // padding-left: 10px;
    margin-bottom: 20px;
    // margin-top: 10px;
    @include respond-to(small) {
        margin-bottom: 20px;
        margin-top: 10px;
    }
}

#add-note-button {
    border: none;
    background: none;
    padding-inline: 0px !important;

    @include respond-to(small) {
        width: 100%;
    }
}

.inside-add-note-button:hover {
    color: $primary-color;
    box-shadow: $accent-box-shadow;
    border: none;
}
.inside-add-note-button:hover .show-on-hover {
    display: flex;
    color: $primary-color;
}

.inside-add-note-button .add-icon {
    height: 25px;
    width: 25px;
    color: rgb(184, 184, 184);
}

.inside-add-note-button:hover .add-icon {
    color: $primary-color;
}

.inside-add-note-button {
    width: 620px;
    height: 80px;
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    column-gap: 5px;
    border-radius: $card-border-radius;
    background: #ffffff;
    border: 2px solid #f2f3f4;
    outline: none;
    color: #aaaaaa;
    font-weight: 400;
    font-size: 14px;
    @include respond-to (small) {
        width: 100%;
    }
}

/* Add note card */
.add-note-card {
    width: 620px;
    min-height: 250px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    row-gap: 0px;
    border-radius: $card-border-radius;
    background: #ffffff;
    padding: 20px 20px 12px 20px;
    line-height: 14px;
    color: var(--secondary-green, $primary-color);
    font-weight: 400;
    border: 2px solid #f2f3f4;
    position: relative;
    overflow: hidden;
    z-index: 1;
    transition: box-shadow $transition-normal;

    &.focused {
        box-shadow: $card-box-shadow;
    }

    @include respond-to (small) {
        width: 100%;
        padding: 20px 20px 10px 20px;
    }
}

.add-note-card-header {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
}

.add-note-card-header svg {
    height: 20px;
    width: 20px;
    color: $tertiary-font-color;
    cursor: pointer;
}

.add-note-title {
    width: 100%;
    margin-bottom: 8px;
    height: 24px;
    border: transparent;
    resize: none;
    font-size: 18px;
    font-weight: 600;
    color: $primary-font-color;
    -ms-overflow-style: none; /* for IE and Edge */
    scrollbar-width: none; /* for Firefox */
    
    &::-webkit-scrollbar {
        display: none; /* for Chrome, Safari, and Opera */
    }
}
.add-note-title:focus {
    outline: none;
}
.add-note-title::placeholder {
    color: #ccc;
}
.add-note-content {
    width: 100%;
    height: auto;
    min-height: 250px;
    margin-bottom: 16px;
    border: transparent;
    resize: none;
    font-size: 16px;
    font-weight: 400;
    line-height: 150%;
    color: var(--basic-black-80, #636363);
}
.add-note-content:focus {
    outline: none;
}

// Add note card footer
.cancel-or-submit {
    width: 100%;
    @include flex-end;
    align-items: center;
    margin-top: 8px;
    overflow: visible;
    position: relative;
    display: flex;
    gap: 0px;
}
    .linked-resource {
        flex-grow: 1;
        height: 30px;
        @include flex-start;
        @include flex-Vcenter;
        position: relative;
        z-index: 99;
        min-width: 0;
        overflow: visible;

        @include respond-to (small) {
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
        }
    }

    .linked-resource svg {
        height: 20px;
        width: 20px;
        color: $tertiary-font-color;
        flex-shrink: 0;
    }
        .linked-resource .link-resource-button {
            @include flex-start;
            align-items: center;
            font-size: $primary-text-size;
            font-weight: $primary-text-weight; 
            max-width: 350px;
            align-items: center;
            column-gap: 3px;
            margin-left: -10px;
            min-width: 0;
            overflow: hidden;
        }
    .note-edit-resource-title {
        overflow-x: hidden;
        overflow-y: visible;
        text-overflow: ellipsis;
        white-space: nowrap;
        @include respond-to (small) {
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
        }
    }

    .private-checkbox .selection-box span {
        @include respond-to (small) {
            display: none;
        }
    }

    .cancel-button {
        color: var(--basic-black-80, #2e2e2e86);
        font-size: 16px;
        font-weight: 500;
        line-height: 24px;
        background-color: transparent;
        border: none;
        pointer-events: stroke;
        margin-right: 15px;
    }