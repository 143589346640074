@import '../../_variables';
@import '../../_mixins';

@keyframes fadeIn {
    from { opacity: 0; }
    to { opacity: 1; }
}

@keyframes slideUp {
    from { transform: translateY(20px); opacity: 0.8; }
    to { transform: translateY(0); opacity: 1; }
}

.ai-feedback-modal {

    width: 620px;
    @include respond-to(small) {
        width: 100%;
        margin: 0 15px;
    }

    .modal-body {
        min-height: 180px;
        max-height: 850px;
        overflow-y: auto;
        background-color: $white;
        
        &.loading {
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            padding: 40px 20px;
            
            p {
                margin-top: 16px;
                color: $secondary-font-color;
                text-align: center;
                font-size: $primary-text-size;
            }
        }
    }

    .ai-feedback-content {
        width: 100%;
        font-size: $primary-text-size;
        line-height: $line-height-loose;
        color: $primary-font-color;
        padding: $spacing-md $spacing-lg;
        border-radius: 0;
        background-color: $white;
        position: relative;
        
        p {
            margin-bottom: $spacing-md;
            font-family: $font-family-sans;
        }
        
        .feedback-summary {
            font-family: $font-family-sans;
            font-size: $primary-text-size;
            line-height: $line-height-loose;
            color: $secondary-font-color;
        }
    }

    .feedback-item {
        margin-bottom: $spacing-lg;
        position: relative;
        padding-left: $spacing-lg;
        font-family: $font-family-sans;
        
        &:last-child {
            margin-bottom: 0;
        }
        
        .feedback-title {
            font-weight: $font-weight-semibold;
            color: $primary-color;
            margin-bottom: $spacing-sm;
            font-size: $primary-text-size;
            display: flex;
            align-items: center;
            
            svg {
                margin-right: 8px;
            }
        }
        
        .feedback-content {
            color: $primary-font-color;
            font-size: $primary-text-size;
            line-height: $line-height-loose;
            font-family: $font-family-sans;
            font-weight: $font-weight-regular;
        }
        
        &:before {
            content: '';
            position: absolute;
            left: 0;
            top: 6px;
            bottom: 0;
            width: 3px;
            background-color: $primary-color;
            border-radius: 3px;
        }
    }

    .feedback-item.insight {
        &:before { background-color: $primary-color; }
        .feedback-title { color: $primary-color; }
    }

    .feedback-item.extension {
        &:before { background-color: $secondary-color; }
        .feedback-title { color: $secondary-color; }
    }

    .feedback-item.critical {
        &:before { background-color: $badge-color; }
        .feedback-title { color: $badge-color; }
    }

    .feedback-item.connection {
        &:before { background-color: $success-color; }
        .feedback-title { color: $success-color; }
    }

    .feedback-item.practical {
        &:before { background-color: $badge-color; }
        .feedback-title { color: $badge-color; }
    }

    // 移除旧的加载样式
    .loader-container {
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 30px 0;
    }

}

@keyframes spin {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
} 