@import '../../../Stylesheets/_variables.scss';

.setting-page {

  .setting-item {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 15px 0px;
    border-radius: 5px;
    border-bottom: none;

    &.clickable {
      cursor: pointer;
    }

    span {
      font-size: 16px;
    }

    .secondary-text {
      font-size: 14px;
      color: #999;
      display: flex;
      align-items: center;
      gap: 5px;

      .chevron-icon {
        padding-top: 1px;
        color: #999;
      }

      &:hover {
        color: $primary-color;
        .chevron-icon {
          color: $primary-color;
        }
      }
    }



    &.profile-link {
      flex-wrap: wrap;
    }

    .link-container {
      display: flex;
      align-items: center;
      gap: 5px;
      flex-wrap: wrap;
    }

    .link {
      color: $tertiary-font-color;
      cursor: pointer;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      display: flex;
      align-items: center;
      flex-wrap: wrap;
      &:hover {
        text-decoration: underline;
      }
      @media (max-width: 670px) {
        margin-left: 0px;
        white-space: normal;
      }
    }

    .copy-icon {
      cursor: pointer;
      margin-top: 2px;
      padding-top: 2px;
      color: #999;

      &:hover {
        color: $primary-color;
      }
    }

    h3 {
      font-size: 16px;
      font-weight: 500;
      margin-bottom: 8px;
    }
  }

  .description {
    font-size: 14px;
    color: $tertiary-font-color;
    margin: 4px 0;
  }

  .toggle {
    align-items: flex-start;

    > div {
      flex-grow: 1;
    }
  }

  // Toggle Styles
  .toggle-container {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    padding: 20px 0;
    gap: 20px;
    border-bottom: none;

    > div {
      flex: 1;
      min-width: 0;
    }

    li {
      list-style-type: disc;
      margin-left: 20px;
    }
    
    h3 {
      font-size: 16px;
      font-weight: 500;
      margin-bottom: 8px;
    }
  }

  .toggle-switch {
    position: relative;
    display: inline-block;
    width: 36px;
    height: 20px;
    flex-shrink: 0;

    input {
      opacity: 0;
      width: 0;
      height: 0;
    }

    .toggle-slider {
      position: absolute;
      cursor: pointer;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      background-color: $grey-background;
      transition: .4s;
      border-radius: 20px;
      display: flex;
      align-items: center;
      justify-content: center;

      &:before {
        position: absolute;
        content: "";
        height: 16px;
        width: 16px;
        left: 2px;
        bottom: 2px;
        background-color: #999;
        transition: .4s;
        border-radius: 50%;
      }
    }

    input:checked + .toggle-slider {
      background-color: $secondary-color;

      &:before {
        transform: translateX(16px);
        background-color: white;
      }
    }

    .toggle-label {
      color: $secondary-font-color;
      font-size: 12px;
      font-weight: bold;

      &::before {
        color: $secondary-color;
      }
    }
  }

  .sub-setting {
    padding: 15px 0;
  }

  .setting-section {
    margin-top: 20px;
    border-top: 1px solid #eee;
    padding-top: 20px;
    
    h2 {
      font-size: 18px;
      font-weight: 500;
      margin-bottom: 15px;
      color: $secondary-font-color;
    }
  }
  
  .analytics-info {
    width: 100%;
    
    .analytics-header {
      display: flex;
      align-items: center;
      gap: 10px;
      margin-bottom: 10px;
      
      .analytics-icon {
        color: $primary-color;
        font-size: 18px;
        margin-top: 2px;
      }
      
      h3 {
        margin-bottom: 0;
      }
    }
    
    ul.description {
      margin: 10px 0;
      padding-left: 20px;
      
      li {
        margin-bottom: 5px;
        position: relative;
      }
    }
    
    .privacy-link {
      display: inline-flex;
      align-items: center;
      gap: 5px;
      color: $primary-color;
      text-decoration: none;
      
      &:hover {
        text-decoration: underline;
      }
    }
  }

}