@import '../../variables';
@import '../../mixins';

.RichEditor-root {
  background: #fff;
  font-size: 16px;
  font-family: $font-family-sans;
  color: $primary-font-color;
  line-height: 1.6;
  letter-spacing: 0.003em;
  text-rendering: optimizeLegibility;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  word-break: normal;
  overflow-wrap: break-word;
}

.RichEditor-root:lang(zh), 
.RichEditor-root:lang(zh-CN) {
  line-height: 1.8;
  letter-spacing: 0.02em;
}

.RichEditor-root .cn-punct {
  margin-left: -0.5em;
  margin-right: 0.25em;
}

.RichEditor-root p {
  margin: 8px 0;
  font-size: 16px;
  letter-spacing: 0.003em;
  line-height: 1.6;
}

.RichEditor-root i {
  font-style: italic;
}

.RichEditor-root a {
  color: $primary-color;
  text-decoration: underline;
  text-decoration-color: rgba($primary-color, 0.3);
  transition: text-decoration-color 0.2s;
  
  &:hover {
    text-decoration-color: rgba($primary-color, 0.9);
  }
}

.RichEditor-controls {
  font-family: $font-family-sans;
  font-size: 14px;
  margin-bottom: 10px;
  user-select: none;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
}

.RichEditor-styleButton {
  color: $tertiary-font-color;
  cursor: pointer;
  margin-right: 16px;
  padding: 2px 0;
  display: inline-block;

  &:hover {
    color: $primary-color;
  }
}


.RichEditor-content {
  cursor: text;
  min-height: 150px;
  position: relative;
}

.tiptap-editor p {
  margin: 8px 0;
  font-size: 16px;
  line-height: 1.6;
  letter-spacing: 0.003em;
}

.tiptap-editor h1,
.tiptap-editor h2, 
.tiptap-editor h3 {
  margin: 16px 0 8px 0;
  font-size: 17px;
  font-weight: 700;
  line-height: 1.3;
  letter-spacing: -0.01em;
}

.tiptap-editor blockquote {
  border-left: 3px solid $border-light;
  color: $secondary-font-color;
  font-style: italic;
  margin: 20px 0 !important;
  padding: 5px 10px !important;
  position: relative;
  
  + blockquote {
      margin-top: 10px !important;
  }
  
  h1, h2, h3 {
      margin-top: 16px;
      margin-bottom: 8px;
      color: $secondary-font-color;
      font-style: italic;
      background-color: transparent;
      font-size: 17px;
      font-weight: 700;
  }
  
  ul, ol, .md-list {
      padding-left: 24px !important;
      margin: 6px 0 !important;
      background-color: transparent;
      
      li, .md-list-item {
          color: $secondary-font-color;
          font-style: italic;
          margin-bottom: 4px !important;
          background-color: transparent;
      }
  }
}

.tiptap-editor ul, .tiptap-editor ol {
  padding-left: 24px !important;
  margin: 6px 0;
  background-color: transparent;
}

.tiptap-editor ul {
  list-style-type: disc !important;
}

.tiptap-editor ul li {
  display: list-item;
  list-style-type: disc !important;
  margin: 6px 0;
}

.tiptap-editor ol li {
  display: list-item;
  list-style-type: decimal !important;
  margin: 6px 0;
}

.tiptap-editor ul ul, .tiptap-editor ol ol, .tiptap-editor ul ol, .tiptap-editor ol ul {
  margin: 6px 0;
  padding-left: 24px !important;
  background-color: transparent;
}

.tiptap-editor ul ul li {
  list-style-type: circle !important;
}

.tiptap-editor ul ul ul li {
  list-style-type: square !important;
}

.tiptap-editor ol ol li {
  list-style-type: lower-alpha !important;
}

.tiptap-editor ol ol ol li {
  list-style-type: lower-roman !important;
}

.tiptap-editor {
  code {
    background-color: $border-light;
    padding: 2px 4px;
    border-radius: 4px;
    font-family: monospace;
    white-space: pre-wrap;
  }

  pre {
    background-color: $border-light;
    border-radius: 4px;
    padding: 15px;
    margin: 8px 0;
    overflow-x: auto;
    width: 100%;

    code {
      background-color: transparent;
      padding: 0;
      border-radius: 0;
      white-space: pre-wrap;
      display: block;
      overflow-x: auto;
      color: $primary-font-color;
      font-family: monospace;
    }
  }
}

.tiptap-editor img {
  max-width: 100%;
  height: auto;
}

.tiptap-placeholder {
  color: #999;
  overflow: hidden;
  position: absolute;
  pointer-events: none;
  top: 0;
  left: 0;
  user-select: none;
  padding: 0.5em 0;
}

.tiptap-editor:focus-within + .tiptap-placeholder {
  display: none;
}

.ProseMirror:focus {
  outline: none;
}

.ProseMirror:not(.is-empty) + .tiptap-placeholder {
  display: none;
}

.ProseMirror ul {
  list-style-type: disc !important;
  padding-left: 24px !important;
}

.ProseMirror ol {
  list-style-type: decimal !important;
  padding-left: 24px !important;
}

.ProseMirror li {
  margin: 6px 0;
  display: list-item !important;
} 

.ProseMirror ul ul,
.ProseMirror ol ol,
.ProseMirror ul ol,
.ProseMirror ol ul {
  margin: 6px 0;
  padding-left: 24px !important;
}

.ProseMirror ul ul li {
  list-style-type: circle !important;
}

.ProseMirror ul ul ul li {
  list-style-type: square !important;
}

.ProseMirror ol ol li {
  list-style-type: lower-alpha !important;
}

.ProseMirror ol ol ol li {
  list-style-type: lower-roman !important;
}

.tiptap-editor:lang(zh), 
.tiptap-editor:lang(zh-CN),
.ProseMirror:lang(zh),
.ProseMirror:lang(zh-CN) {
  line-height: 1.8;
  letter-spacing: 0.02em;
}

.tiptap-editor:lang(zh) ul, 
.tiptap-editor:lang(zh) ol,
.tiptap-editor:lang(zh-CN) ul,
.tiptap-editor:lang(zh-CN) ol,
.ProseMirror:lang(zh) ul,
.ProseMirror:lang(zh) ol,
.ProseMirror:lang(zh-CN) ul,
.ProseMirror:lang(zh-CN) ol {
  padding-left: 2em;
}

.link-modal {
  background: $white;
  width: 400px;
  padding: 24px;
  border-radius: 12px;
  animation: modalFadeIn 0.2s ease;
}

.link-modal-title {
  font-size: 18px;
  font-weight: 600;
  color: $primary-font-color;
  margin-bottom: 16px;
}

.link-modal-input {
  width: 100%;
  padding: 12px 16px;
  border-radius: 8px;
  font-size: 14px;
  color: $primary-font-color; 
  background: $light-background;
  transition: all 0.2s ease;
  margin-bottom: 10px;
  border: 0px solid $border-light;

  &:focus {
    outline: none;
  }
}

.link-modal-buttons {
  display: flex;
  justify-content: flex-end;
  width: 100%;
  gap: 12px;
}

.link-modal-button {
  padding: 10px 16px;
  border-radius: 8px;
  font-size: 14px;
  font-weight: 500;
  cursor: pointer;
  transition: all 0.2s ease;
  border: none;
}